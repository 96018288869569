import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path
      clipRule="evenodd"
      d="M16.682 1a4.21 4.21 0 0 0-4.217 4.203 4.21 4.21 0 0 0 4.217 4.203A4.21 4.21 0 0 0 20.9 5.203 4.21 4.21 0 0 0 16.682 1Zm-1.847 4.203c0-1.013.825-1.838 1.847-1.838s1.847.825 1.847 1.838a1.842 1.842 0 0 1-1.847 1.838 1.842 1.842 0 0 1-1.847-1.838ZM7.25 4.356a4.21 4.21 0 0 0-4.217 4.203 4.21 4.21 0 0 0 4.217 4.202 4.21 4.21 0 0 0 4.218-4.202 4.21 4.21 0 0 0-4.218-4.203ZM5.402 8.559c0-1.013.825-1.838 1.847-1.838s1.847.825 1.847 1.838a1.842 1.842 0 0 1-1.847 1.837A1.842 1.842 0 0 1 5.402 8.56Zm11.28 1.837c-1.88 0-3.651.718-4.989 1.982a1.18 1.18 0 0 0-.044 1.674c.384.403.986.476 1.449.211l.04.007.186-.175a4.869 4.869 0 0 1 3.359-1.334c2.696 0 4.879 2.177 4.879 4.857v1.344c0 .654.532 1.182 1.185 1.182.652 0 1.185-.527 1.185-1.182v-1.344c0-3.99-3.248-7.222-7.25-7.222ZM7.25 13.752C3.247 13.752 0 16.983 0 20.975v1.342c0 .655.533 1.183 1.185 1.183.653 0 1.185-.528 1.185-1.183v-1.342c0-2.681 2.182-4.858 4.88-4.858 2.696 0 4.878 2.177 4.878 4.858v1.342c0 .655.533 1.183 1.185 1.183.653 0 1.186-.528 1.186-1.183v-1.342c0-3.991-3.248-7.223-7.25-7.223Z"
    />
  </Svg>
)

export default SvgComponent
