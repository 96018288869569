import {
  IButtonProps,
  Text,
  Flex,
  Button
} from "native-base"
import { cloneElement } from "react"
import { useNavigate, useLocation } from "react-router-dom"

export interface IconLinkProps extends IButtonProps {
    text: string
    icon?: JSX.Element
    path?: string
    color?: string
    activeColor?: string
}

const MobileNavLink = ({
  color = "primary.600",
  activeColor = "primary.700",
  icon,
  path,
  text,
  ...props
}: IconLinkProps): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = location.pathname === path

  const customIcon =
        icon &&
        cloneElement(icon, {
          fill: "white",
          width: "20px"
        })

  return (
    <Button
      onPress={() => {
        path && navigate(path)
      }}
      height="100%"
      justifyContent="center"
      alignItems="center"
      bg={isActive ? activeColor : color}
      _pressed={{ bg: activeColor }}
      borderRadius={"14px"}
      py={2}
      flexDirection="column"
      {...props}
    >
      <Flex alignItems={"center"}>
        {customIcon}
        <Text
          color="white"
          fontSize="md"
          fontFamily={"GTPressuraPro"}
        >
          {text}
        </Text>
      </Flex>
    </Button>
  )
}

export default MobileNavLink
