import { Factory } from "fishery"

import Transaction from "models/transactions"

export const TransactionFactory = Factory.define<Transaction>(
  ({ sequence }) => ({
    id: sequence,
    ledgerId: sequence,
    cardId: sequence,
    cardDetails: `cardDetails ${sequence}`,
    additionalInfo: `additionalInfo ${sequence}`,
    merchantDetails: `merchantDetails ${sequence}`,
    employeeName: `employeeName ${sequence}`,
    card: `physical card ${sequence}`,
    budget: `budget ${sequence}`,
    amount: sequence * 42,
    amountBeneficiaryAccount: sequence * 42,
    amountLedgerFrom: sequence * 42,
    amountLocalCurrency: sequence * 42,
    assetClass: sequence,
    assetType: sequence,
    imageSrc: `assets/images/imageSrc.png ${sequence}`,
    beneficiaryId: "95ba10bf-d356-4652-bb78-e1749a58507f",
    cardCurrency: "sample string 6",
    cardEntryMethod: "sample string 7",
    cardExpiryDate: "sample string 8",
    cardTransactionType: "sample string 9",
    cardUsed: "sample string 10",
    conversionDate: "2021-10-25T07:07:13.9840789+00:00",
    conversionRate: sequence,
    createdAt: new Date().toISOString(),
    dailyUniqueReference: "sample string 11",
    debitPaymentId: "f73195a5-06c3-4f5f-8c50-4d77c61697e7",
    eventGroupCreatedAt: "2021-10-25T07:07:13.9840789+00:00",
    eventGroupId: `e7212711-e4d7-4225-a244-18058fe52a29-${sequence}`,
    fixedSide: 1,
    ledgerFromId: "60040be4-0aef-4fb4-b0e2-36b7fa1d0edd",
    ledgerToId: "84acf62e-9d85-43d9-b8d6-17772788dca1",
    mccDescription: "sample string 12",
    merchantCategoryCode: "sample string 13",
    merchantId: "sample string 15",
    merchantBankId: "sample string 16",
    partnerProduct: 1,
    partnerProductFx: 1,
    paymentMethod: 1,
    paymentType: 1,
    pointOfSaleCountry: "sample string 17",
    pointOfSaleInfo: "sample string 18",
    pointOfSaleReference: "sample string 19",
    reason: "sample string 20",
    receiptId: "sample string 21",
    reference: "sample string 22",
    settlementDate: "2021-10-25T07:07:13.9997237+00:00",
    swiftChargeBearer: 1,
    swiftServiceLevel: 1,
    transactionAuditNumber: 1,
    transactionCurrency: "1 USD",
    transactionFee: 1.0,
    externalReference: "5696f77f-c793-4787-a8a5-361f3833d034",
    transactionStatus: "sample string 24",
    transactionType: 1,
    payload: "sample string 25",
    hasReceipts: false
  })
)

export const filterTransactions = {
  total: 1,
  items: [TransactionFactory.build()]
}

export const transactionsList = {
  total: 10,
  items: [
    TransactionFactory.build({ displayStatus: "Pending" }),
    ...TransactionFactory.buildList(9, { ledgerId: 1 })
  ]
}

export const emptyFilteredList = {
  total: 0,
  items: []
}
