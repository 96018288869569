import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { RootState } from "store"
export type JwtTokenValidState = boolean

const slice = createSlice({
  name: "jwtTokenValid",
  initialState: true as JwtTokenValidState,
  reducers: {
    setJwtTokenValidity: (
      state,
      { payload: jwtTokenValid }: PayloadAction<boolean>
    ) => (state = jwtTokenValid)
  }
})

export const { setJwtTokenValidity } = slice.actions
export default slice.reducer
export const selectJwtTokenValid = (state: RootState): boolean => {
  return state.jwtTokenValid
}


