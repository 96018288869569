import {
  Alert,
  Heading,
  Row,
  CloseIcon,
  Text
} from "native-base"

import IconButton from "components/inline/IconButton"

interface ErrLogProps {
  title: string
  description: string
  closeToast: () => void
}

export const ErrLog = ({ title, description, closeToast }: ErrLogProps): JSX.Element => {

  return (
    <Alert color="white" bg="red.300" status="error" variant="errorToast">
      <Row space={8} alignItems="flex-start">
        <Alert.Icon color="white"/>
        <Heading size="sm" color="white">{title}</Heading>

        <IconButton
          key="close-error-toast"
          testID="close-toast"
          onPress={() => closeToast()}
          icon={<CloseIcon color="white" />}
          iconColor="white"
        />
      </Row>
      <Row>
        <Text color="white" mt="8px">{description}</Text>
      </Row>
    </Alert>
  )
}

export default ErrLog
