import { getBoolEnv } from "components/util/get-bool-env"

// import { REACT_APP_DOMAIN, REACT_APP_API_BASE, REACT_APP_LOGGLY_API_BASE, REACT_APP_LOGGLY_CUSTOMER_TOKEN, REACT_APP_COMPILE_MODE, REACT_APP_GTM } from "@env"

export const DOMAIN = process.env.REACT_APP_DOMAIN
export const API_BASE_URL = process.env.REACT_APP_API_BASE
export const LOGGLY_API_BASE_URL = process.env.REACT_APP_LOGGLY_API_BASE
export const LOGGLY_CUSTOMER_TOKEN = process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN
export const COMPILE_MODE = process.env.REACT_APP_COMPILE_MODE
export const REACT_HIDE_INJECTION_WARNING = getBoolEnv(process.env.REACT_HIDE_INJECTION_WARNING)
export const GTM = process.env.REACT_APP_GTM

// feature flags
export const FF__RESET_PASSWORD = getBoolEnv(process.env.REACT_APP_FF__RESET_PASSWORD)
export const FF__UPDATE_PROFILE = getBoolEnv(process.env.REACT_APP_FF__UPDATE_PROFILE)
export const FF__SETTINGS_PAGE = getBoolEnv(process.env.REACT_APP_FF__SETTINGS_PAGE)
export const FF__GOOGLE_OAUTH = getBoolEnv(process.env.REACT_APP_FF__GOOGLE_OAUTH)
export const FF__TRANSACTIONS_DOWNLOAD = getBoolEnv(process.env.REACT_APP_FF__TRANSACTIONS_DOWNLOAD)
export const FF__SPEND_CONTROLS = getBoolEnv(process.env.REACT_APP_FF__SPEND_CONTROLS)
export const FF__TWO_FA = getBoolEnv(process.env.REACT_APP_FF__TWO_FA)
export const FF__CONNECTIONS_PAGE = getBoolEnv(process.env.REACT_APP_FF__CONNECTIONS_PAGE)
export const FF__BUDGETS = getBoolEnv(process.env.REACT_APP_FF__BUDGETS)
export const FF__RECEIPT_CAPTURE = getBoolEnv(process.env.REACT_APP_FF__RECEIPT_CAPTURE)
export const FF__NEW_DASHBOARD = getBoolEnv(process.env.REACT_APP_FF__NEW_DASHBOARD)


// build string
export const AXLE_BUILD = process.env.REACT_APP_AXLE_BUILD
export const AXLE_VERSION = process.env.REACT_APP_AXLE_VERSION
export const DISABLE_SECURE_COOKIE = getBoolEnv(process.env.REACT_APP_DISABLE_SECURE_COOKIE)
export const REACT_APP_USE_MOCK = getBoolEnv(process.env.REACT_APP_USE_MOCK)

