import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { removeDuplicateTransaction } from "components/util/transactionFilters"
import TransactionDto from "models/transactions"

import type { RootState } from "store"

export type TransactionsState = {
  hasMoreTransactions: boolean
  transactions: TransactionDto[]
  currentPage: number
}

export type TransactionUpdatePayload = {
  id: number
  transaction: TransactionDto
}

const slice = createSlice({
  name: "transactions",
  initialState: {
    hasMoreTransactions: false,
    transactions: [],
    currentPage: 0
  } as TransactionsState,
  reducers: {
    setTransactions: (
      state,
      { payload: transactions }: PayloadAction<TransactionDto[]>
    ) => {
      state.transactions = removeDuplicateTransaction([...state.transactions, ...transactions])
    },
    resetTransactions: (
      state
    ) => {
      state.transactions = []
      state.currentPage = 0
      state.hasMoreTransactions = false
    },
    setHasMoreTransactions: (
      state,
      { payload: hasMoreTransactions }: PayloadAction<boolean>
    ) => { state.hasMoreTransactions = hasMoreTransactions },
    incrementCurrentPage: (
      state
    ) => { state.currentPage++ }
  }
})

export const {
  setTransactions,
  resetTransactions,
  setHasMoreTransactions,
  incrementCurrentPage
} = slice.actions
export default slice.reducer
export const fetchTransactions = (state: RootState): TransactionsState => state.transactions
