import { useNavigate, useLocation } from "react-router-dom"

import IconButton, { IIconButtonProps } from "../IconButton"

export interface IconLinkProps extends IIconButtonProps {
  path?: string
}

const IconLink = ({ path, ...props }: IconLinkProps): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = location.pathname === path

  return (
    <IconButton
      fontWeight="normal"
      onPress={() => {
        path && navigate(path)
      }}
      isActive={isActive}
      {...props}
    />
  )
}

export default IconLink
