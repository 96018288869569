import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { AdminControlsDto, BudgetOwnerControlsDto, ExpensePoliciesDto, RulesList } from "models/spendControls"
import prepareHeaders from "network/util/prepare-headers"

import { ENTITY_API_BASE } from "./apiNamespaces"

export interface GetSpendControls_Response {
  success: boolean
  data: AdminControlsDto | BudgetOwnerControlsDto | RulesList
}

export interface GetExpensePolicies_Response {
  success: boolean
  data: ExpensePoliciesDto
}

export const spendControls = createApi({
  reducerPath: "spendControlsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ENTITY_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getSpendControls: builder.mutation<GetSpendControls_Response, void>({
      query: () => ({
        url: "rules",
        method: "GET"
      })
    }),
    updateSpendControls: builder.mutation<GetSpendControls_Response, AdminControlsDto>({
      query: (adminControls: AdminControlsDto) => ({
        url: "rules",
        method: "PUT",
        body: adminControls
      })
    })

    // getExpensePolicies: builder.mutation<GetExpensePolicies_Response, void>({
    //   query: () => ({
    //     url: "spendControls/expensePolicies",
    //     method: "GET"
    //   })
    // })
  })
})

export const {
  useGetSpendControlsMutation,
  useUpdateSpendControlsMutation,
  // useGetExpensePoliciesMutation,
  reducer,
  middleware
} = spendControls
