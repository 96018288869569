import TransactionDto from "models/transactions"

export const removeDuplicateTransaction = (
  transaction: TransactionDto[]
): TransactionDto[] => {
  return transaction.reduce<TransactionDto[]>((result, cur) => {
    let duplicated = false
    cur.eventGroupId &&
      result.forEach((ele, index) => {
        if (ele.eventGroupId === cur.eventGroupId) {
          if (ele.createdAt && cur.createdAt) {
            const d1 = Date.parse(ele.createdAt)
            const d2 = Date.parse(cur.createdAt)
            if (d1 < d2) {
              result[index] = cur
            }
          }
          duplicated = true
        }
      })

    if (!duplicated) {
      result = [...result, cur]
    }
    return result
  }, [])
}

export const removeHiddenTransaction = (
  transaction: TransactionDto[]
): TransactionDto[] => transaction.filter((item) => item.hide !== true)
