import React, { useCallback, useEffect, useMemo } from "react"

import { Provider } from "./context"
import LogglyTracker from "./tracker"

let previous: { data: LogData|string|null, level: string|null }
let previousError: { data: ErrorLogData | ExtendedErrorLogData | LogData | string | null, level: string|null }

let instance = new LogglyTracker()


type Callback = (instance: LogglyTracker, key: string, level: string, data: LogData) => unknown
type Providers = Record<string, Callback>

type ErrorLogData = {
  column: number
  file: string
  line: number
  stack: string
  message: string
}

type LogData = Record<string, unknown>|string

type ExtendedErrorLogData = Record<string, unknown>|ErrorLogData & undefined

export function logDataIsString(data: LogData): data is LogData {
  return "string" === typeof data
}

const logErrorFn = (
  level: string,
  errorData: ErrorLogData,
  logData: LogData,
  providers: Providers,
  once: boolean
) => {
  // if (!instance.key) {
  //   return;
  // }

  let dataObject: ErrorLogData | (ErrorLogData & LogData)
  dataObject = errorData

  Object.entries(providers).forEach(([key, callback]) => {
    dataObject = Object.assign(
      dataObject,
      callback(instance, key, level, logData)
    )
  })

  dataObject = Object.assign(dataObject, { level: level })

  if (logDataIsString(logData)) {
    dataObject = Object.assign(dataObject, { text: logData })
  } else {
    dataObject = Object.assign(dataObject, logData)
  }

  if (!once || !previousError) {
    instance.track(dataObject)
  }

  previousError = { data: dataObject, level }
}

const logFn = (level: string, logData: LogData, providers: Providers, once: boolean) => {

  // if (!instance.key) {
  //   return;
  // }

  let dataObject: LogData

  if (logDataIsString(logData)) {
    dataObject = { text: logData }
  } else {
    dataObject  = logData
  }

  Object.entries(providers).forEach(([ key, callback ]) => {
    dataObject = Object.assign(dataObject, callback(instance, key, level, logData))
  })

  if (
    !once
    || !previous
  ) {
    dataObject = Object.assign(dataObject, { level: level })
    instance.track(dataObject)
  }

  previous = { data: dataObject, level }

}

export type LogglyOptionsData = {
  key: boolean
  session_id: string
  // useDomainProxy:boolean
  inputUrl: string
  logglyCollectorDomain?: string
  sendConsoleErrors: boolean
  instance?: LogglyTracker
}

interface Props {
  options: LogglyOptionsData
  providers: Providers
}

const LogglyProvider: React.FC<Props> = ({ children, options, providers }): JSX.Element => {

  options = useMemo(() => ({
    logglyKey: process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
    tag: process.env.REACT_APP_LOGGLY_TAG,
    useUtfEncoding: true,
    ...options
  }), [ options ])

  if (options.instance) {
    instance = options.instance
  }

  providers = useMemo(() => ({
    ...providers,
    url: (instance, key/* , level, data*/) => ({ [key]: window.location.href }),
    userAgent: (instance, key/* , level, data*/) => ({ [key]: window.navigator.userAgent })
  }), [ providers ])

  const info = useCallback((data, once = false) => {
    logFn("info", data, providers, once)
  }, [ providers ])

  const warn = useCallback((data, once = false) => {
    logFn("warn", data, providers, once)
  }, [ providers ])

  const error = useCallback((err, data = {}, once = false) => {
    logErrorFn("error", {
      file: err.fileName || err.filename,
      line: err.lineNumber || err.lineno,
      column: err.colno,
      message: err.message,
      stack: err.stack || (err.error ? err.error.stack : undefined)
    }, data, providers, once)
  }, [ providers ])

  const globalErrorHandler = useCallback((err) => error(err, undefined, true), [ error ])

  useEffect(() => {

    instance.config({
      ...options,
      logglyKey: process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
      sendConsoleErrors: true,
      tag: process.env.REACT_APP_LOGGLY_TAG,
      useUtfEncoding: true
    })

    if (!options.sendConsoleErrors) {
      return
    }

    // Use our own global error handler to ensure errors are always reported with the same structure
    // and repeated errors are only reported once

    window.addEventListener("error", globalErrorHandler)

    return () => {
      window.removeEventListener("error", globalErrorHandler)
    }

  }, [ globalErrorHandler, options ])

  return (
    <Provider value={{ error, info, instance, providers, warn }}>
      {children}
    </Provider>
  )

}

export default LogglyProvider
