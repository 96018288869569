import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { CardDto } from "models/card"

import type { RootState } from "store"

export type CurrentCardState = CardDto | null

const slice = createSlice({
  name: "currentCard",
  initialState: null as CurrentCardState,
  reducers: {
    setCurrentCard: (
      state,
      { payload: currentCard }: PayloadAction<CurrentCardState>
    ) => (state = currentCard)
  }
})

export const { setCurrentCard } = slice.actions
export default slice.reducer
export const selectCurrentCard = (state: RootState): CurrentCardState => {
  return state.currentCard
}


