import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={21}
    height={8}
    viewBox="0 0 21 8"
    fill="none"
    {...props}
  >
    <Path
      d="M10.454 7.294c-1.917 0-4.015-.544-5.906-1.533C2.536 4.712.954 3.241.091 1.621A.76.76 0 0 1 .387.597a.735.735 0 0 1 1.008.3c1.15 2.139 3.578 3.676 6.018 4.407 1.198.268 3.041.914 6.09 0 2.441-.731 4.87-2.268 6.019-4.406a.743.743 0 0 1 1.008-.3c.36.2.493.657.296 1.023-.871 1.62-2.454 3.091-4.466 4.14-1.891.989-3.989 1.533-5.906 1.533Z"
      fill="#979797"
    />
  </Svg>
)

export default SvgComponent
