import { rest } from "msw"

import { userChangePasswordEndpoint, usersLoginEndpoint, usersStartLoginEndpoint } from "config/endpoints"

const guid = "f7nkygU1d"
const username = "wat@where.now"
const provider = "Cape"
const jwt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cm46SGVsbG9DYXBlQ3VzdG9tQ2xhaW06VXNlcm5hbWUiOiJzdGV2ZUBoZWxsb2NhcGUuY29tIiwidXJuOkhlbGxvQ2FwZUN1c3RvbUNsYWltOkVudGl0eUlkIjoiMSIsIm5iZiI6MTYzNDY2MzYxMCwiZXhwIjoxNjM0NjY3MjEwLCJpYXQiOjE2MzQ2NjM2MTEsImlzcyI6Ind3dy5oZWxsb2NhcGUuY29tIiwiYXVkIjoid3d3LmhlbGxvY2FwZS5jb20ifQ.2Nk0V4PJc7Wm29qEZoyAOTBi9rqgUzRpUGvc9A1JJF4"
const jwtOnboard = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cm46SGVsbG9DYXBlQ3VzdG9tQ2xhaW06VXNlcm5hbWUiOiJvbmJvYXJkQGhlbGxvY2FwZS5jb20iLCJ1cm46SGVsbG9DYXBlQ3VzdG9tQ2xhaW06RW50aXR5SWQiOiIxIiwibmJmIjoxNjM0NjYzNjEwLCJleHAiOjE2MzQ2NjcyMTAsImlhdCI6MTYzNDY2MzYxMSwiaXNzIjoid3d3LmhlbGxvY2FwZS5jb20iLCJhdWQiOiJ3d3cuaGVsbG9jYXBlLmNvbSJ9.f8FRrjkx1qaTs7sGPbLiKXaOZBcOS8Zwp8ZWNszYbKc"

export interface LoginRequest {
  username: string
  password: string
}

export const endpoints = [
  rest.get(`${usersStartLoginEndpoint}/:email`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({
        data: { guid, username, provider }
      })
    )
  }),
  rest.options(usersLoginEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({
        token: jwt
      })
    )
  }),
  rest.get(usersLoginEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({
        token: jwt
      })
    )
  }),
  rest.post(usersLoginEndpoint, (req, res, ctx) => {
    const requestBody = req?.body as LoginRequest

    const isOnboarding = requestBody.username.includes("onboard")

    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({
        token: isOnboarding ? jwtOnboard : jwt
      })
    )
  }),
  rest.post(userChangePasswordEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({ success: true })
    )
  })
]
