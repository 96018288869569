import { Middleware, isPending, isFulfilled } from "@reduxjs/toolkit"

import { send } from "store/slices/openReplay"

interface networkStore {
  startedTime: number
  id: string
}

const networkStore = [] as networkStore[]

export const openSession: Middleware = (storeApi) => (next) => (action) => {
  if (isPending(action)) {
    networkStore.push({
      startedTime: action.meta.startedTimeStamp,
      id: action.meta.requestId
    })
  }

  if (isFulfilled(action)) {
    networkStore.forEach((item, index) => {
      if (item.id === action.meta.requestId) {

        action.meta.arg.originalArgs?.password && delete action.meta.arg.originalArgs.password

        const timeTaken = action.meta.fulfilledTimeStamp - item.startedTime
        const method = action.meta.baseQueryMeta?.request.method
        const url = action.meta.baseQueryMeta?.request.url
        const requestData = action.meta.arg.originalArgs ? JSON.stringify(action.meta.arg.originalArgs) : ""
        const responseData = action.payload ? JSON.stringify(action.payload) : ""
        const status = action.meta.baseQueryMeta?.response.status

        storeApi.dispatch(send({
          method,
          url,
          requestData,
          responseData,
          status,
          fulfilledTimeStamp: action.meta.fulfilledTimeStamp,
          timeTaken
        }))

        networkStore.splice(index, 1)
      }
    })
  }

  return next(action)
}
