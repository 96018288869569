
import { useMemo } from "react"

import { CardDto } from "models/card"
import { useAppSelector } from "store/hooks"
import { fetchCards } from "store/slices/cards"

export const useCards = (): { cards: CardDto[]|null } => {
  const cards = useAppSelector(fetchCards)

  return useMemo(() => ({ cards }), [cards])
}

// export const useGpsCard = (): { card: CardDto } => {
//   const card = useAppSelector(fetchGpsCard)

//   return useMemo(() => ({ card }), [card])
// }
