import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import Notification from "models/notification"
import prepareHeaders from "network/util/prepare-headers"

import { NOTIFICATIONS_API_BASE } from "./apiNamespaces"

export interface GetNotification_Request {
  id?: number
  ledgerId?: number
  Page: number
  PageSize: number
}

export interface GetNotification_Response {
  success: boolean
  data: {
    total: number
    items: Notification[]
  }
}
export interface SetReadNotificationsResponse {
  success: boolean
}

export const notifications = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: NOTIFICATIONS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getNotifications: builder.mutation<GetNotification_Response, GetNotification_Request>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    }),

    setReadNotifications: builder.mutation<SetReadNotificationsResponse, void>({
      query: () => ({
        url: "/markread",
        method: "PUT"
      })
    })
  })
})

export const { useGetNotificationsMutation, useSetReadNotificationsMutation, reducer, middleware } = notifications
