import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { notification as notificationRoute, statement as statementRoute } from "components/layouts/Authorized/routes"
import { EventTypes } from "models/enums/event-type"
import NotificationDto from "models/notification"
import { useSetReadNotificationMutation } from "network/notification"
import { useGetNotificationsMutation } from "network/notifications"
import { useUnreadNotiCount } from "store/hooks/notification"

interface useNotificationLink {
  unreadNotiCount: number
  notificationList: NotificationDto[]
  loadNotification: () => Promise<void>
  handleViewAllLink: () => void
  handleView: (notification: NotificationDto) => Promise<void>
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  menuOpen: boolean
}

const useNotificationLink = (): useNotificationLink => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const { unreadNotiCount } = useUnreadNotiCount()
  const [getNotifications] = useGetNotificationsMutation()
  const [notificationList, setNotificationList] = useState<NotificationDto[]>([])
  const [setReadNotification] = useSetReadNotificationMutation()

  const navigate = useNavigate()

  const loadNotification = async () => {
    setMenuOpen(true)
    const getNotificationResponse = await getNotifications({
      Page: 0,
      PageSize: 3
    }).unwrap()

    getNotificationResponse.success && setNotificationList(getNotificationResponse.data.items)
  }

  const handleView = async (notification: NotificationDto) => {
    if (!notification.readAt) {
      await setReadNotification({
        eventId: notification.id
      }).unwrap()
    }
    notification.type === EventTypes.StatementReady
      ? navigate(statementRoute(notification.statementId))
      : navigate(notificationRoute)
    setMenuOpen(false)
  }

  const handleViewAllLink = () => {
    setMenuOpen(false)
    navigate(notificationRoute)
  }

  // tear down component state
  useEffect(() => {
    return () => {
      setNotificationList([])
      setMenuOpen(false)
    }
  }, [])

  return {
    unreadNotiCount,
    notificationList,
    loadNotification,
    handleViewAllLink,
    handleView,
    setMenuOpen,
    menuOpen
  }
}
export default useNotificationLink
