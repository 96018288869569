import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path d="M23.611 5.92c0-.66-.606-1.198-1.35-1.198H4.635l2.947-2.667c.524-.465.524-1.223 0-1.712C7.306.098 6.975 0 6.617 0s-.688.122-.964.343L.393 5.064a1.104 1.104 0 0 0 0 1.689l5.285 4.746c.248.22.606.367.964.367s.689-.123.964-.343c.248-.22.413-.538.413-.856 0-.318-.138-.636-.385-.856L4.576 7.12h17.655c.774 0 1.38-.539 1.38-1.2ZM17.355 24c.358 0 .716-.122.964-.367l5.289-4.746a1.104 1.104 0 0 0 0-1.688L18.319 12.5c-.523-.465-1.377-.465-1.928 0-.523.465-.523 1.223 0 1.713l2.975 2.642H1.738c-.743 0-1.35.538-1.35 1.199 0 .66.607 1.199 1.35 1.199h17.655l-3.002 2.69c-.248.221-.385.54-.385.857 0 .318.137.612.413.857.248.22.58.342.936.342Z" />
  </Svg>
)

export default SvgComponent
