import { useEffect } from "react"

import { CardDto } from "models/card"
import { Processor } from "models/enums/processor"
import { useAppDispatch } from "store/hooks"
import { useCards } from "store/hooks/cards"
import {
  useLedgers,
  useGpsLedger,
  useRailsLedger
} from "store/hooks/ledgers"
import { setCurrentCard } from "store/slices/currentCard"
import { setCurrentCardIndex } from "store/slices/currentCardIndex"
import { setPersonsLedger } from "store/slices/person"


interface Props {
  children: JSX.Element
}

export default function ProcessorProvider({ children }: Props): JSX.Element {
  const dispatch = useAppDispatch()
  const {
    ledgerState: { items: ledgers, selectedProcessor }
  } = useLedgers()
  const { ledger: railsLedger } = useRailsLedger()
  const { ledger: gpsLedger } = useGpsLedger()
  const { cards } = useCards()

  function setGPS(cards: CardDto[]) {
    let cardIndex = -1
    const currentCard = cards.find((card) => {
      cardIndex++
      return card.processor === Processor.Gps
    })

    currentCard &&
      dispatch(setCurrentCard(currentCard)) &&
      dispatch(setCurrentCardIndex(cardIndex))

    dispatch(setPersonsLedger(gpsLedger))
  }

  function setRails(cards: CardDto[]) {
    let cardIndex = -1
    const currentCard = cards.find((card) => {
      cardIndex++
      return card.processor === Processor.Rails
    })
    currentCard &&
      dispatch(setCurrentCard(currentCard)) &&
      dispatch(setCurrentCardIndex(cardIndex))

    dispatch(setPersonsLedger(railsLedger))
  }

  useEffect(() => {
    if (ledgers.length === 0) return

    cards && selectedProcessor === Processor.Gps && setGPS(cards)
    cards && selectedProcessor === Processor.Rails && setRails(cards)
  }, [selectedProcessor, ledgers, cards])

  return children
}
