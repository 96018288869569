import { createAction } from "@reduxjs/toolkit"
import { useCookies } from "react-cookie"

import { useLogoutUser } from "components/hooks/useLogoutUser"
import { useAppDispatch } from "store/hooks"

import IconButton from "../IconButton"

interface Props {
  svgHeight?: string
  svgWidth?: string
  fontSize?: string
  color?: string
  disableSecureCookie?: boolean
}

export const SignOutButton = ({
  color = "warmGray.600",
  fontSize = "12px"
}: Props): JSX.Element => {
  const [, , removeCookie] = useCookies(["jwt", "username", "ddSkip"])
  const { logoutUser } = useLogoutUser()
  const dispatch = useAppDispatch()
  const reset = createAction("reset")

  const signOut = () => {
    dispatch(reset())
    logoutUser()
    removeCookie("ddSkip", {
      path: "/",
      domain: process.env.REACT_APP_DOMAIN
    })
  }

  return (
    <IconButton
      testID="sign-out-button"
      onPress={() => signOut()}
      fontSize={fontSize}
      color={color}
      text="Log out"
    />
  )
}

export default SignOutButton
