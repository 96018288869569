// DASHBOARD
export const dashboard = "/dashboard"

export const connectionsSegment = "/connections"
export const connections = `${dashboard}${connectionsSegment}`

export const spendControlsSegment = "/spend-controls"
export const spendControls = `${dashboard}${spendControlsSegment}`

export const profileSegment = "/profile"
export const profile = `${dashboard}${profileSegment}`

export const activateCardSegment = "/activate-card"
export const activateCard = `${dashboard}${activateCardSegment}`

export const myCardsSegment = "/my-cards"
export const myCards = `${dashboard}${myCardsSegment}`

export const budgetsSegment = "/budgets"
export const budgets = `${dashboard}${budgetsSegment}`

export const changePasswordSegment = "/change-password"
export const changePassword = `${dashboard}${changePasswordSegment}`

export const expensesSegment = "/expenses"

export const transactionsSegment = `${expensesSegment}/transactions`
export const transactions = `${dashboard}${transactionsSegment}`

export const receiptsSegment = `${expensesSegment}/receipts`
export const receipts = `${dashboard}${receiptsSegment}`

export const dashboardHelpCentreSegment = "/help-centre"
export const dashboardHelpCentre = `${dashboard}${dashboardHelpCentreSegment}`

export const settingsSegment = "/settings"
export const settings = `${dashboard}${settingsSegment}`

export const paymentsSegement = "/payments"
export const payments = `${dashboard}${paymentsSegement}`

export const freezeCardSegment = "/freeze-card"
export const freezeCard = `${dashboard}${freezeCardSegment}`

export const unFreezeCardSegement = "/unfreeze-card"
export const unFreezeCard = `${dashboard}${unFreezeCardSegement}`

export const replaceCardSegment = "/replace-card"
export const replaceCard = `${dashboard}${replaceCardSegment}`

export const orderCardSegment = "/order-card"
export const orderCard = `${dashboard}${orderCardSegment}`

export const pinReminderSegment = "/pin-reminder"
export const pinReminder = `${dashboard}${pinReminderSegment}`

export const statementSegment = (statementId: number | string): string => `/statement/${statementId}`
export const statement = (statementId: number | string): string => `${dashboard}/statement/${statementId}`

export const notificationSegment = "/notification"
export const notification = `${dashboard}${notificationSegment}`

export const notificationPageSegment = (notificationID: number | string) => `/notification/${notificationID}`
export const notificationPage = (notificationID: number | string) => `${dashboard}/notification/${notificationID}`

export const twoFAStartSegment = "/start"
export const twoFAStart = `${dashboard}/2fa${twoFAStartSegment}`

export const twoFAConfirmationSegment = "/confirmation"
export const twoFAConfirmation = `${dashboard}/2fa${twoFAConfirmationSegment}`

export const peopleSegment = "/people"
export const people = `${dashboard}${peopleSegment}`

export const peopleAddSegment = "/people/add"
export const peopleAdd = `${dashboard}${peopleAddSegment}`

export const activateFlowDDSetupSegment = "/setup"
export const activateFlowDDSetup = `${dashboard}${activateFlowDDSetupSegment}`

// ONBOARDING
export const onboarding = "/onboarding"

export const ddSetupSegment = "/setup"
export const ddSetup = `${onboarding}${ddSetupSegment}`

export const obHelpCentreSegment = "/help-centre"
export const obHelpCentre = `${onboarding}${obHelpCentreSegment}`

export const obOrderCardSegment = "/order-card"
export const obOrderCard = `${onboarding}${obOrderCardSegment}`
