import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.076 2.7a1.285 1.285 0 0 1 1.245-.012l11.46 6.203c.062.033.12.071.175.113l-.002.002-12.305 8.991a.027.027 0 0 1-.016.006.029.029 0 0 1-.017-.006l-11.88-8.68c.11-.183.268-.338.46-.447l10.88-6.17ZM2.553 12.447v11.695l6.357-7.058-6.357-4.637ZM.102 27.073A3.825 3.825 0 0 0 3.83 30h22.34c2.115 0 3.83-1.702 3.83-3.8V10.002c0-1.39-.765-2.67-1.996-3.336L16.544.464a3.856 3.856 0 0 0-3.734.037L1.93 6.67A3.795 3.795 0 0 0 0 9.97V26.2c0 .24.023.476.066.704L0 26.977l.102.096Zm3.134.248 7.814-8.677 2.04 1.489h.001c.448.33.988.51 1.544.513h.001a2.621 2.621 0 0 0 1.546-.501l.002-.001 2.047-1.497 7.947 8.819H3.83a1.28 1.28 0 0 1-.594-.145Zm24.21-2.383V11.914l-7.076 5.17 7.077 7.854Z"
      fill="#70A3C9"
    />
  </Svg>
)

export default SvgComponent
