import * as React from "react"
import { Svg, SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <G clipPath="url(#a)">
      <Path d="M7.955 4.992a.896.896 0 1 0 0-1.792.896.896 0 0 0 0 1.792ZM9.92 11.2H8.64V6.08H6.72a.64.64 0 0 0 0 1.28h.64v3.84H6.08a.64.64 0 1 0 0 1.28h3.84a.64.64 0 1 0 0-1.28Z" />
      <Path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 14.667A6.666 6.666 0 1 1 8 1.334a6.666 6.666 0 0 1 0 13.333Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SvgComponent
