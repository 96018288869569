import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path
      clipRule="evenodd"
      d="M0 3.798c0-.67.543-1.212 1.212-1.212h21.576a1.212 1.212 0 0 1 0 2.424H1.212C.542 5.01 0 4.467 0 3.798Z"
    />
    <Path
      d="M8.162 6.384a2.586 2.586 0 1 0 0-5.172 2.586 2.586 0 0 0 0 5.172Z"
      fill="#fff"
    />
    <Path
      clipRule="evenodd"
      d="M8.162 2.424a1.374 1.374 0 1 0 0 2.748 1.374 1.374 0 0 0 0-2.748ZM4.364 3.798a3.798 3.798 0 1 1 7.596 0 3.798 3.798 0 0 1-7.596 0ZM0 12.04c0-.669.543-1.212 1.212-1.212h21.576a1.212 1.212 0 0 1 0 2.425H1.212C.542 13.252 0 12.71 0 12.04Z"
    />
    <Path
      d="M15.838 14.626a2.586 2.586 0 1 0 0-5.171 2.586 2.586 0 0 0 0 5.171Z"
      fill="#fff"
    />
    <Path
      clipRule="evenodd"
      d="M15.838 10.667a1.374 1.374 0 1 0 0 2.747 1.374 1.374 0 0 0 0-2.747ZM12.04 12.04a3.798 3.798 0 1 1 7.596 0 3.798 3.798 0 0 1-7.596 0ZM0 20.202c0-.67.543-1.212 1.212-1.212h21.576a1.212 1.212 0 0 1 0 2.424H1.212c-.67 0-1.212-.543-1.212-1.212Z"
    />
    <Path
      d="M8.162 22.788a2.586 2.586 0 1 0 0-5.172 2.586 2.586 0 0 0 0 5.172Z"
      fill="#fff"
    />
    <Path
      clipRule="evenodd"
      d="M8.162 18.828a1.374 1.374 0 1 0 0 2.748 1.374 1.374 0 0 0 0-2.748Zm-3.798 1.374a3.798 3.798 0 1 1 7.596 0 3.798 3.798 0 0 1-7.596 0Z"
    />
  </Svg>
)

export default SvgComponent
