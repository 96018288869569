export enum CardStatus {
  Active = 1,
  Failed = 2,
  Reactivating = 3,
  Suspending = 4,
  AwaitingActivation = 5,
  Created = 6,
  Suspended = 7,
  Closed = 8,
  Activating = 9,
  EntityStatusInternalError = 10,
}
