import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { Invite } from "models/invite"


import { SIGN_UP_API_BASE } from "./apiNamespaces"
import ApiResponse from "./util/api-response"


export interface SetCredentialsResponse {
  success: boolean;
}

export interface SetCredentialsRequest {
  guid: string;
  password: string;
}

export interface SetOAuthCredentialsRequest {
  Email: string;
  FamilyName: string;
  GivenName: string;
  GoogleId: string;
  Guid: string;
  ImageUrl: string;
  Name: string;
}

export const auth = createApi({
  reducerPath: "signUpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SIGN_UP_API_BASE,
    prepareHeaders: (headers) => {
      return headers
    }
  }),
  endpoints: (builder) => ({
    setCredentials: builder.mutation<
      SetCredentialsResponse,
      SetCredentialsRequest | SetOAuthCredentialsRequest
    >({
      query: (data) => ({
        url: "credentials",
        method: "POST",
        body: data
      })
    }),
    start: builder.query<ApiResponse<Invite>, string>({
      query: (guid) => `start/${guid}`
    })
  })
})

export const {
  useSetCredentialsMutation,
  useStartQuery,
  reducer,
  middleware
} = auth
