import { useMemo } from "react"

import { useAppSelector } from "store/hooks"
import { selectCurrentGlobalToast, GlobalToastState } from "store/slices/globalToast"

export const useCurrentGlobalToast = (): GlobalToastState => {
  const globalToast = useAppSelector(selectCurrentGlobalToast)

  return useMemo(() => (globalToast), [globalToast])
}
