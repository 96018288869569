import { Button, Flex, Modal, Row, ArrowBackIcon, Column, CloseIcon, Heading, Pressable } from "native-base"
import { useMemo } from "react"
import { Platform } from "react-native"
import { useNavigate, useLocation } from "react-router-dom"

import IconButton from "components/inline/IconButton"
import SignOut from "components/inline/SignOut"
import SwitchProcessor from "components/inline/SwitchProcessor"
import {
  dashboard as dashboardRoute
} from "components/layouts/Authorized/routes"
import NotificationLink from "components/pages/Notifications/NotificationLink"
import HamburgerIcon from "components/svgs/hamburger"
import MainLogo from "components/svgs/logo"
import { FF__RECEIPT_CAPTURE } from "config/environment"
import pathNames from "config/pageTitles"

import useBurgerNav, { BtnLinkprops } from "./talons/useBurgerNav"

const BurgerNav = ({ receiptCaptureEnabled = FF__RECEIPT_CAPTURE }): JSX.Element => {
  const {
    personNavList,
    expensesNavList,
    footerList,
    navList,
    menuOpen,
    setMenuOpen
  } = useBurgerNav({
    receiptCaptureEnabled
  })

  const notifictionsPagePath = "notification/"

  const { pathname } = useLocation()
  const title = useMemo(() => {
    if(pathname.includes(notifictionsPagePath)) return "Notifications"
    return pathNames.find((path) => path.path === pathname)?.title
  }, [pathname])

  const navigate = useNavigate()

  const isDashboardPage = pathname === dashboardRoute

  const svgSize = {
    width: "36px",
    height: "36px"
  }

  const renderList = (list: BtnLinkprops[]): JSX.Element[] => {
    const buttonList = list.map((btn) => {
      const { ...btnprops } = btn

      if (btn.isHeader) {
        return (
          <Heading key={btn.testID} fontSize="xl" pb={6}>
            {btn.text}
          </Heading>
        )
      }

      return (
        <IconButton
          key={btnprops.path}
          onPress={() => {
            navigate(btnprops.path)
            setMenuOpen(false)
          }}
          {...btnprops}
          pb={6}
          iconColor="primary.600"
        />
      )
    })

    return buttonList
  }
  /* istanbul ignore next */
  const overlayStyle =
    Platform.OS === "web"
      ? {
        position: "fixed"
      }
      : {}

  return (
    <Column position="fixed" top="0" left="0" w="100%" display={{ base: "flex", md: "none" }} testID="burger-menu">
      <Row
        alignItems="center"
        bg="primary.600"
        px={4}
        py={3.5}
        w="100%"
        justifyContent={"space-between"}
      >
        <Row alignItems="center">
          {isDashboardPage ? <>
            <Button w={10} p={1} testID="burger-nav-button" bg="transparent" onPress={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <CloseIcon style={{ fontSize: 20, color: "tertiary.50" }} /> : <HamburgerIcon height="18px" width="18px" />}
            </Button>
            <NotificationLink showIcon />
          </> : <>
            <Pressable testID="page-head-back-button" onPress={() => navigate(-1)}>
              <ArrowBackIcon size="lg" color="white" />
            </Pressable>
            <Heading pl={7} color="white" testID="burger-heading">{title}</Heading>
          </>}
        </Row>

        <MainLogo height="28px" width="26px" />
      </Row>

      <Modal
        isOpen={menuOpen}
        size="full"
        // Special case for this because Nativebase used React Native ViewProps Types which doesnt allow 'position: fixed'
        /* eslint-disable */
        //@ts-ignore
        _overlay={{ style: overlayStyle }}
        _backdrop={{ display: "none" }}
        testID={"burger-menu"}
      >
        <Modal.Content h="100%" maxH="100%" mb="0" mt="111px" borderRadius={"0px"} testID="menu">
          <Modal.Body>
            <Flex flexDir="column" px={4} pt={4} pb="200px">
              <Row pb={6}>
                <SwitchProcessor colorMode="dark" callback={setMenuOpen} />
              </Row>

              {renderList(personNavList)}
              {renderList(navList)}
              {renderList(expensesNavList)}
              {renderList(footerList)}

              <SignOut color="warmGray.600" fontSize="lg" svgWidth={svgSize.width} svgHeight={svgSize.height} />
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Column>
  )
}

export default BurgerNav
