import jwtDecode from "jwt-decode"
import {
  Button,
  useToast } from "native-base"
import { useCookies } from "react-cookie"
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin
} from "react-google-login"
import { useNavigate } from "react-router-dom"

import {
  dashboard as dashboardRoute
} from "components/layouts/Authorized/routes"
import GoogleIcon from "components/svgs/google"
import { DISABLE_SECURE_COOKIE } from "config/environment"
import { useSetOAuthCredentialsMutation as useSetOAuthMutation } from "network/oauth"

import { stringToBase64 } from "../../util/base64"

interface GooglOauthProps {
  guid: string,
  disableSecureCookie?: boolean
}

type OnlineOrOfflineResponse = GoogleLoginResponse | GoogleLoginResponseOffline;

const secureCookieDisabled = DISABLE_SECURE_COOKIE

const GoogleOauth = ({
  guid,
  disableSecureCookie = secureCookieDisabled
}: GooglOauthProps): JSX.Element => {
  const [setOAuth] = useSetOAuthMutation()
  const clientId = "974442144331-4fp96hrc4i5blv2n7uj3i5irtn69gflf.apps.googleusercontent.com"
  const toast = useToast()
  const navigate = useNavigate()

  const [, setCookie] = useCookies(["jwt", "username"])

  const isLoginResponse = (
    response: OnlineOrOfflineResponse
  ): response is GoogleLoginResponse => {
    return !!(response as GoogleLoginResponse).code
  }

  const onSuccess = async (res: OnlineOrOfflineResponse) => {
    if (isLoginResponse(res)) {
      const base64Code = stringToBase64(res.code as string)
      const oauthCredentials = await setOAuth({
        authorizationToken: base64Code,
        guid,
        provider: "Google"
      }).unwrap()

      // TODO: type error here with destructure oauthCredentials
      if (oauthCredentials.data) {
        const { success, data } = oauthCredentials
        if (success) {
          const jwtFromBackend = data.token
          const decodedJwt: Record<string, unknown> =
                    jwtDecode(jwtFromBackend)

          // make the call to the backend with the guid and auth token to retrieve the jwt
          // then set the received jwt in a cookie for future use:
          // const jwtFromBackend = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
          setCookie(
            "username",
            decodedJwt["urn:HelloCapeCustomClaim:Username"],
            {
              path: "/",
              secure: !disableSecureCookie,
              domain: process.env.REACT_APP_DOMAIN
            }
          )
          setCookie("jwt", jwtFromBackend, {
            path: "/",
            secure: !disableSecureCookie,
            domain: process.env.REACT_APP_DOMAIN
          })
          navigate(dashboardRoute)
        } else {
          toast.show({
            title: "Error",
            description: oauthCredentials.errors
          })
        }
      } else {
        toast.show({
          title: "Error",
          description: "Failed to return oauth token!"
        })
      }
    }
  }

  const onFailure = () => {
    toast.show({
      title: "Error",
      description: "Oh no, there was an error with Google!"
    })
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    responseType: "code",
    accessType: "offline",
    prompt: "consent",
    redirectUri: `${process.env.REACT_APP_BASE}/signin`
  })

  return(
    <Button
      leftIcon={<GoogleIcon width="40px" height="20px" />}
      colorScheme="red.300"
      variant="outline"
      onPress={signIn}
      testID="google-button"
    >
      Sign in with google
    </Button>
  )
}

export default GoogleOauth
