import { rest } from "msw"

import { limitsEndpoint } from "config/endpoints"

export const endpoints = [
  rest.put(limitsEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({ success: true })
    )
  })
]
