import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { RootState } from "store"

export type OpenReplayState = {
  message?: OpenReplayMsg
  username?: string
  counter: number
}

export type OpenReplayMsg = {
  timeTaken: number
  method: string
  url: string
  requestData: string
  responseData: string
  status: number
  fulfilledTimeStamp: number
}

const slice = createSlice({
  name: "openReplay",
  initialState: { counter: 0 } as OpenReplayState,
  reducers: {
    send: (
      state,
      { payload }: PayloadAction<OpenReplayMsg>
    ) => {
      state.message = payload
      state.counter += 1
    },
    setUsername: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.username = payload
    }
  }
})

export const { send, setUsername } = slice.actions

export default slice.reducer

export const selectCurrentOpenReplay = (state: RootState): OpenReplayState => state.openReplay
