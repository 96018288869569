import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import BlobDto from "models/blob"
import NoteDto from "models/note"
import tagDto from "models/tag"
import prepareHeaders from "network/util/prepare-headers"

import { RECEIPTS_API_BASE } from "./apiNamespaces"

export interface AddReceiptResponse {
  success: boolean
}

export interface AddReceiptNoteRequest {
  description: string
  gpsTransactionId: number
}

export interface AddReceiptNoteResponse {
  success: boolean,
  data: NoteDto
}

export interface DeleteReceiptNoteResponse {
  success: boolean
}

export interface GetReceiptRequest {
  id: string
}

export interface GetReceiptResponse {
  success: boolean
  data: string
}

export const receipts = createApi({
  reducerPath: "receiptsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: RECEIPTS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    addReceipt: builder.mutation<
      {
        success: boolean,
        data: BlobDto
      },
      {
        transactionID: number,
        data: FormData
      }
    >({
      query: ({ data, transactionID }) => ({
        url: `/addReceipt/${transactionID}`,
        method: "POST",
        body: data
      })
    }),
    addReceiptNote: builder.mutation<
      AddReceiptNoteResponse,
      AddReceiptNoteRequest
    >({
      query: (data) => ({
        url: "/addMemo",
        method: "POST",
        body: data
      })
    }),
    removeReceiptNote: builder.mutation<
      DeleteReceiptNoteResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/deleteMemo/${id}`,
        method: "DELETE"
      })
    }),
    getReceipt: builder.mutation<
      GetReceiptResponse,
      { id: number | undefined }
    >({
      query: ({ id }) => ({
        url: `/receipt/${id}`,
        method: "GET"
      })
    }),
    addTag: builder.mutation<
      {
        success: true,
        data: {
          id: number,
          blobId: number,
          description: string,
          entityId: number,
          legalName: string,
          tradingName: string
        }
      },
      {
        blobId: number,
        description: string,
        entityId: number
      }
    >({
      query: (data) => ({
        url: "/addTag",
        method: "POST",
        body: data
      })
    }),
    linkTag: builder.mutation<
      {
        success: true,
        data: tagDto
      }, {
        tagId: number,
        blobId: number
      }
    >({
      query: (data) => ({
        url: "linkTag",
        method: "POST",
        body: data
      })
    }),
    unlinkTag: builder.mutation<
      {
        success: true,
        data: tagDto
      }, {
        id: number
      }
    >({
      query: ({ id }) => ({
        url: `unlinkTag/${id}`,
        method: "DELETE"
      })
    }),
    removeReceipt: builder.mutation<
      { success: boolean },
      { id: number | undefined }
    >({
      query: ({ id }) => ({
        url: `/deleteReceipt/${id}`,
        method: "DELETE"
      })
    })
  })
})

export const {
  useAddReceiptMutation,
  useAddReceiptNoteMutation,
  useRemoveReceiptNoteMutation,
  useRemoveReceiptMutation,
  useAddTagMutation,
  useLinkTagMutation,
  useUnlinkTagMutation,
  useGetReceiptMutation,
  reducer,
  middleware
} = receipts
