import { Factory } from "fishery"

import { EntityDto } from "models/entity"

import { AddressFactory } from "./address"


export const EntityFactory = Factory.define<EntityDto>(({ associations }) => ({
  legalName: "legal name",
  tradingName: "trading name",
  addresses: [AddressFactory.build()],
  state: associations.state || 15, // combined state
  dueDate: "2021-04-14T00:00:00"
}))

// export const EntityFactory = Factory.define<EntityDto>(() => ({
//   id: 1,
//   jurisdictionId: 1,
//   char3Code: "string",
//   char2Code: "string",
//   companyType: 1,
//   code: "string",
//   dueDate: "2021-04-14T00:00:00",
//   legalName: "string",
//   tradingName: "string",
//   configuration: 1,
//   behaviours: 1,
//   logo: "string",
//   primaryColour: "string",
//   externalReference: "string",
//   registrationNumber: "the ABN Number",
//   state: 1,
//   teams: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         entityCode: "string",
//         code: "",
//         description: "string",
//         persons: {
//           total: 1,
//           items: [
//             {
//               id: 1,
//               teamId: 1,
//               code: "string",
//               personId: 1,
//               name: "string"
//             }
//           ]
//         }
//       }
//     ]
//   },
//   cards: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         code: "string",
//         cardId: 1,
//         externalReference: "string",
//         createdAt: "string",
//         cardDetails: "string"
//       }
//     ]
//   },
//   persons: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         uid: "string",
//         entityId: 1,
//         code: "string",
//         personId: 1,
//         name: "string",
//         personType: 1
//       }
//     ]
//   },
//   users: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         userId: 1,
//         username: "string",
//         entityId: 1,
//         code: "string",
//         isSelected: true
//       }
//     ]
//   },
//   addresses: {
//     total: 1,
//     items: [
//       AddressFactory.build()
//     ]
//   },
//   ledgers: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         code: "string",
//         ledgerId: 1,
//         ledgerDetails: "string",
//         externalReference: "string",
//         goCardlessReference: "string",
//         cards: [
//           {
//             id: 1,
//             ledgerId: 1,
//             ledgerDetails: "string",
//             cardId: 1,
//             cardDetails: "string"
//           }
//         ]
//       }
//     ]
//   },
//   allocations: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         legalName: "string",
//         tradingName: "string",
//         amount: 1000,
//         createdAt: "string",
//         from: "01-01-2021",
//         to: "01-02-2021",
//         notes: "string",
//         userId: 1,
//         userName: "string",
//         funderId: 1,
//         funder: "string",
//         defaultProbability: 1,
//         decision: 1
//       }
//     ]
//   },
//   phone: "string",
//   website: "string",
//   email: "string",
//   vat: "string",
//   isListed: true,
//   isRegulated: true,
//   industry: "string",
//   documentId: "string",
//   goCardlessReference: "string",
//   frankieReference: "string",
//   frankies: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         entityDetails: "string",
//         createdEntityId: "string",
//         createdAt: "string",
//         checkedId: "string",
//         function: "string",
//         requestedId: "string"
//       }
//     ]
//   },
//   ratingId: 1,
//   codatReference: "string",
//   secret: "string",
//   startDate: "string",
//   anzsicId: 1,
//   notes: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         code: "string",
//         noteId: 1,
//         description: "string",
//         createdAt: "string",
//         userId: 1,
//         userName: "string"
//       }
//     ]
//   },
//   blobs: {
//     total: 1,
//     items: [
//       {
//         id: 1,
//         entityId: 1,
//         code: "string",
//         blobId: 1,
//         guid: "string",
//         mimeType: "string",
//         originalFileName: "string",
//         description: "string",
//         createdAt: "string",
//         userId: 1,
//         userName: "string",
//         fileName: "string"
//       }
//     ]
//   },
//   marqetaReference: "string",
//   established: "string",
//   duns: "string",
//   usage: {
//     id: 1,
//     code: "string",
//     legalName: "string",
//     tradingName: "string",
//     allocation: 1,
//     allocated: 1,
//     percentage: 1,
//     next: "string",
//     last: "string",
//     ledgers: [
//       {
//         id: 1,
//         name: "string",
//         externalReference: "string",
//         limit: 10000,
//         balance: 10000,
//         available: 10000,
//         usage: 1
//       }
//     ],
//     totalBalance: 10000
//   }
// }))
