import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={32}
    height={24}
    viewBox="0 0 32 24"
    fill="none"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.016 21.2c.1 1.563 1.36 2.8 2.902 2.8h26.174C30.698 24 32 22.657 32 21V3c0-1.657-1.302-3-2.908-3H2.918C1.312 0 .009 1.343.009 3v18c0 .056.002.113.005.168L0 21.184l.016.015ZM27.892 2.5H3.917L15.8 11.291a.176.176 0 0 0 .211 0L27.892 2.5ZM2.433 4.686V18.45l7.39-8.301-7.39-5.465Zm.8 16.814 8.71-9.783 2.362 1.745v.001c.469.35 1.03.539 1.608.541h.017a2.71 2.71 0 0 0 1.601-.59l2.336-1.722 8.726 9.808H3.233Zm26.343-2.844V4.533l-7.587 5.594 7.587 8.529Z"
      fill="#F59197"
    />
  </Svg>
)

export default SvgComponent
