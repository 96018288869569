import * as React from "react"
import { Svg, SvgProps, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    {...props}
  >
    <Circle cx={6} cy={6} r={6} />
    <Path
      d="m6.733 3.054-.28 3.896h-.896l-.28-3.896h1.456ZM5.31 8.118c0-.192.067-.355.2-.488a.651.651 0 0 1 .488-.208c.192 0 .355.069.488.208a.651.651 0 0 1 .208.488c0 .186-.07.349-.208.488a.664.664 0 0 1-.488.2.681.681 0 0 1-.688-.688Z"
      fill="#fff"
    />
  </Svg>
)

export default SvgComponent
