import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import prepareHeaders from "network/util/prepare-headers"

import { USERS_API_BASE } from "./apiNamespaces"
import ApiResponse from "./util/api-response"

export interface twoFAResponse {
  success: boolean
}

export const twoFA = createApi({
  reducerPath: "twoFAApi",
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    setTwoFA: builder.mutation<ApiResponse<twoFAResponse>, number>({
      query: (communicationType) => ({
        url: `set2fa/${communicationType}`,
        method: "POST"
      })
    })
  })
})

export const { useSetTwoFAMutation, reducer, middleware } = twoFA
