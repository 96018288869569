import { Image } from "native-base"
import React, { Suspense, lazy } from "react"
import { Route, Routes } from "react-router-dom"

import capeImage from "assets/images/cape.png"
import { PrivateRoute } from "components/inline/PrivateRoute"
import AuthorizedRouter from "components/layouts/Authorized/Router"
import LazySpinner from "components/layouts/LazySpinner"
import PublicLayout from "components/layouts/Public"
import * as publicRoutes from "components/layouts/Public/routes"
// import CreditLimitConfirmation from "components/pages/Account/CreditLimitConfirmation"
// import { AccountLocked } from "components/pages/AccountLocked"
import { Expired } from "components/pages/SetCredentials"

const CreditLimitConfirmation = lazy(() => import("components/pages/Account/CreditLimitConfirmation"))
const Login = lazy(() => import("components/pages/Login"))
const AccountLocked = lazy(() => import("components/pages/AccountLocked"))
const ConnectionAuthSuccess = lazy(() => import("components/pages/Connections/ConnectionAuth/ConnectionAuthSuccess"))
const ConnectionAuthFail = lazy(() => import("components/pages/Connections/ConnectionAuth/ConnectionAuthFail"))
const SetCredentials = lazy(() => import("components/pages/SetCredentials"))
const ResetPassword = lazy(() => import("components/pages/ResetPassword/ResetPassword"))
const SessionExpired = lazy(() => import("components/pages/SessionExpired"))
const TwoFAVerifcation = lazy(() => import("components/pages/TwoFAVerification"))
const TooManyCodes = lazy(() => import("components/pages/TooManyCodes"))

function PublicRouter(): JSX.Element {
  return (
    <Routes>
      <Route
        path={publicRoutes.login}
        element={
          <PublicLayout>
            <Suspense fallback={<LazySpinner />}>
              <Login
                capeCardImage={() => (
                  <Image
                    width={{ base: "325px", xl: "480px" }}
                    height={{ base: "325px", xl: "480px" }}
                    src={capeImage}
                    alt="capeImage"
                  />
                )}
              />
            </Suspense>
          </PublicLayout>
        }
      />
      <Route
        path={publicRoutes.resetPassword(":guid")}
        element={
          <Suspense fallback={<LazySpinner />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route path={publicRoutes.setCredentials(":guid")} element={
        <Suspense fallback={<LazySpinner />}>
          <SetCredentials />
        </Suspense>
      } />
      <Route path={publicRoutes.linkExpired} element={<Expired />} />
      <Route
        path={publicRoutes.sessionExpired}
        element={
          <Suspense fallback={<LazySpinner />}>
            <SessionExpired />
          </Suspense>
        }
      />
      <Route
        path={publicRoutes.twoFA}
        element={
          <Suspense fallback={<LazySpinner />}>
            <TwoFAVerifcation />
          </Suspense>
        }
      />
      <Route path={publicRoutes.lockedAccount} element={
        <Suspense fallback={<LazySpinner />}>
          <AccountLocked />
        </Suspense>
      } />
      <Route path={publicRoutes.tooManyCodes} element={
        <Suspense fallback={<LazySpinner />}>
          <TooManyCodes />
        </Suspense>
      } />
      <Route path={publicRoutes.creditLimitConfirm(":guid")} element={
        <Suspense fallback={<LazySpinner />}>
          <CreditLimitConfirmation />
        </Suspense>
      } />

      <Route path={publicRoutes.connectionAuthSuccess} element={
        <Suspense fallback={<LazySpinner />}>
          <ConnectionAuthSuccess />
        </Suspense>
      } />
      <Route path={publicRoutes.connectionAuthFail} element={
        <Suspense fallback={<LazySpinner />}>
          <ConnectionAuthFail />
        </Suspense>
      } />

      <Route
        path="/*"
        element={
          <PrivateRoute>
            <AuthorizedRouter data-testid="App" />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}

export default PublicRouter
