import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path d="M11.258 8.926h1.485c.643 0 1.187.5 1.187 1.093h1.88c0-1.503-1.287-2.733-2.87-2.824V6.149a.374.374 0 0 0-.38-.366H11.44a.374.374 0 0 0-.38.366v1.046c-1.584.09-2.87 1.32-2.87 2.824 0 1.548 1.385 2.823 3.067 2.823h1.484c.643 0 1.188.502 1.188 1.094 0 .592-.544 1.093-1.188 1.093h-1.484c-.643 0-1.187-.501-1.187-1.093H8.19c0 1.503 1.286 2.733 2.869 2.823v1.092c0 .202.17.366.38.366h1.119c.21 0 .381-.164.381-.366V16.76c1.584-.091 2.87-1.321 2.87-2.824 0-1.549-1.386-2.824-3.068-2.824h-1.484c-.643 0-1.187-.501-1.187-1.093s.495-1.093 1.187-1.093Z" />
    <Path
      clipRule="evenodd"
      d="M10.87 6.149a.56.56 0 0 1 .571-.549h1.118a.56.56 0 0 1 .572.549v.878C14.724 7.202 16 8.47 16 10.02v.182h-2.26v-.182c0-.484-.452-.91-.998-.91h-1.484c-.59 0-.996.42-.997.91 0 .484.452.91.997.91h1.484c1.78 0 3.258 1.35 3.258 3.007 0 1.55-1.276 2.816-2.87 2.991v.924a.56.56 0 0 1-.571.549H11.44a.56.56 0 0 1-.572-.549v-.924C9.276 16.752 8 15.485 8 13.936v-.183h2.261v.183c0 .484.451.91.997.91h1.484c.546 0 .997-.426.997-.91 0-.484-.451-.91-.997-.91h-1.484c-1.78 0-3.258-1.35-3.258-3.007 0-1.55 1.276-2.816 2.87-2.992V6.15Zm.571-.183a.187.187 0 0 0-.19.183v1.218l-.18.01c-1.49.086-2.69 1.242-2.69 2.642 0 1.44 1.293 2.64 2.877 2.64h1.484c.741 0 1.378.576 1.378 1.277 0 .7-.637 1.276-1.378 1.276h-1.484c-.675 0-1.264-.479-1.363-1.094H8.388c.099 1.316 1.258 2.377 2.683 2.459l.18.01v1.264c0 .101.085.183.19.183h1.118c.105 0 .19-.082.19-.183v-1.264l.18-.01c1.49-.086 2.69-1.242 2.69-2.641 0-1.44-1.292-2.641-2.877-2.641h-1.484c-.74 0-1.378-.576-1.378-1.276 0-.695.583-1.276 1.378-1.276h1.484c.676 0 1.265.478 1.364 1.093h1.506c-.099-1.316-1.258-2.377-2.683-2.459l-.18-.01V6.149a.187.187 0 0 0-.19-.183h-1.118Z"
    />
    <Path
      clipRule="evenodd"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-12 9.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Z"
    />
  </Svg>
)

export default SvgComponent
