
import { useFonts } from "expo-font"
import { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"

import { useLogoutUser } from "components/hooks/useLogoutUser"
import PublicRouter from "components/layouts/Public/Router"
import VersionSyncComp from "components/providers/VersionSync"
import { DISABLE_SECURE_COOKIE } from "config/environment"


const secureCookieDisabled = DISABLE_SECURE_COOKIE

interface App {
  disableSecureCookie?: boolean
  VersionSync?: typeof VersionSyncComp
}

function App({ disableSecureCookie = secureCookieDisabled, VersionSync = VersionSyncComp }: App): JSX.Element|null {
  const { logoutUser } = useLogoutUser()
  useFonts({
    "CircularStdBlack": require("assets/fonts/CircularStdBlack.otf"),
    "CircularStdMedium": require("assets/fonts/CircularStdMedium.otf"),
    "CircularStdBook": require("assets/fonts/CircularStdBook.otf"),
    "GTPressuraPro": require("assets/fonts/GTPressuraProBold.otf")
  })

  let onlineInterval: ReturnType<typeof setInterval>
  const updateLoginTime = () => {
    onlineInterval = setInterval(() => {
      const currentTime = new Date().getTime().toString()
      localStorage.setItem("lastOnlineTime", currentTime)
    }, 1000)
  }

  useEffect(() => {
    const tenSecondsInMills = 10 * 1000
    const currentTime = new Date().getTime()
    const lastSessionTime = Number(localStorage.getItem("lastOnlineTime") ?? "0")

    const sessionClosedDuration = currentTime - lastSessionTime

    if (sessionClosedDuration > tenSecondsInMills) {
      logoutUser()
    }

    updateLoginTime()
  }, [disableSecureCookie])

  useEffect(() => {
    return () => {
      clearInterval(onlineInterval)
    }
  }, [])

  return (
    <BrowserRouter>
      <VersionSync>
        <PublicRouter />
      </VersionSync>
    </BrowserRouter>
  )
}

export default App
