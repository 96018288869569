import { rest } from "msw"

import { statementEndpoint } from "config/endpoints"
import { StatementFactory } from "mocks/factories/newStatement"

const statementId = 123

export const endpoints = [
  rest.get(
    statementEndpoint(`${statementId}`),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.set("Content-Type", "application/json"),
        ctx.json({
          data: { ...StatementFactory.build() },
          success: true
        })
      )
    }
  )
]
