import { Box, Flex, Image, VStack, useBreakpointValue } from "native-base"

import logo from "assets/svgs/public-logo.svg"
import NavLink from "components/inline/IconLink/NavLink"
import AuthorizedLayout from "components/layouts/Authorized"
import { obHelpCentre as helpCentreRoute } from "components/layouts/Authorized/routes"
import EarPhoneIcon from "components/svgs/ear-phone"
import { Roles } from "models/enums/roles"

import BurgerNav from "./BurgerNav"

export interface Props {
  children: JSX.Element | JSX.Element[] | null
  settingsPageDisabled?: boolean
}

const OnboardingLayout = ({ children }: Props): JSX.Element => {
  // const imageSize = useBreakpointValue({ base: "40px", sm: "50px" })
  const widthSize = useBreakpointValue({ base: "50px", sm: "42px" })
  const heightSize = useBreakpointValue({ base: "54px", sm: "70px" })

  return (
    <AuthorizedLayout burgerNav={<BurgerNav />} roles={[Roles.None]}>
      <Flex
        flexDirection="row"
        px={{ base: "2", md: "10" }}
        mt={{ base: "2", md: "60px" }}
        paddingBottom="112px"
        width="100%"
      >
        {/* Nav Menu  */}
        <Box
          testID="onboarding-nav-links"
          w="160px"
          display={{ base: "none", md: "flex" }}
        >
          <Flex
            position="fixed"
            h="100%"
            top="0"
            left="0"
            pb="48px"
            pt="24px"
            flexDirection={{ base: "unset", md: "column" }}
          >
            {/* icon */}
            <Flex justifyContent="flex-start" px="32px">
              <Image
                width={widthSize}
                height={heightSize}
                resizeMode="contain"
                testID="Public"
                src={logo}
                alt="logo"
              />
            </Flex>

            {/* Bottom  Nav*/}
            <VStack alignItems="flex-start" flexBasis="75%" mt="64px">
              <NavLink
                testID="onboarding-help-centre"
                path={helpCentreRoute}
                icon={<EarPhoneIcon width="24px" height="24px" />}
                text="Help Centre"
                mb="4"
              />
            </VStack>
          </Flex>
        </Box>

        <Box testID="onboarding-hub" width="100%" flex={1}>
          {children}
        </Box>
      </Flex>
    </AuthorizedLayout>
  )
}

export default OnboardingLayout
