import { useCookies } from "react-cookie"
import { Navigate } from "react-router-dom"

import { login as loginRoute } from "components/layouts/Public/routes"

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const [cookies] = useCookies(["jwt"])

  return cookies["jwt"] ? children : <Navigate to={loginRoute} />
}
