import { useMemo } from "react"

import { useAppSelector } from "store/hooks"
import { selectCurrentCardIndex } from "store/slices/currentCardIndex"



export const useCurrentCardIndex = (): { currentCardIndex: number|null } => {
  const currentCardIndex = useAppSelector(selectCurrentCardIndex)

  return useMemo(() => ({ currentCardIndex }), [currentCardIndex])
}
