import { rest } from "msw"

import { logglyEndpoint } from "config/endpoints"

export const endpoints = [
  rest.post(`${logglyEndpoint}/inputs/123/tag/axle`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.text("Not sending to loggly as running locally")
    )
  })
]
