import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import SpendControlsDto, {
  EntityCategory,
  Category,
  Budget,
  CardRule,
  ExpensePoliciesDto,
  AdminControlsDto,
  BudgetOwnerControlsDto,
  RulesList
} from "models/spendControls"

import type { RootState } from "store"

export type SpendControlsState = SpendControlsDto | null

interface UpdateCategoryByPosition {
  entityCategoryIndex: number
  categoryIndex: number
  category: Category
}

interface UpdateCategoryAubsByPosition {
  entityCategoryIndex: number
  categoryIndex: number
  authorisedBudgetOwners: Budget[]
}

interface UpdateCategoryAllowByPosition {
  entityCategoryIndex: number
  categoryIndex: number
  allow: boolean
}

interface UpdateCategoryRulesByPosition {
  entityCategoryIndex: number
  categoryIndex: number
  rules: CardRule[]
}

const slice = createSlice({
  name: "spendControls",
  initialState: {
    adminControls: null,
    budgetOwnerControls: null,
    expensePolicies: null,
    ruleList: null
  } as SpendControlsDto,
  reducers: {
    setAdminControl: (
      state,
      { payload: adminControls }: PayloadAction<AdminControlsDto>
    ) => {
      return {
        ...state,
        adminControls
      }
    },

    setRulesList: (
      state,
      { payload: ruleList }: PayloadAction<RulesList>
    ) => {
      return {
        ...state,
        ruleList
      }
    },

    setBudgetOwnerControl: (
      state,
      { payload: budgetOwnerControls }: PayloadAction<BudgetOwnerControlsDto>
    ) => {
      return {
        ...state,
        budgetOwnerControls
      }
    },

    setExpensePolicies: (
      state,
      { payload: expensePolicies }: PayloadAction<ExpensePoliciesDto>
    ) => {
      return {
        ...state,
        expensePolicies
      }
    },

    setCategoryByPosition: (
      state,
      {
        payload: { entityCategoryIndex, categoryIndex, category }
      }: PayloadAction<UpdateCategoryByPosition>
    ) => {
      state.adminControls &&
        (state.adminControls.categories.items[
          entityCategoryIndex
        ].categories.items[categoryIndex] = category)
    },
    setCategoryAubByPosition: (
      state,
      {
        payload: { entityCategoryIndex, categoryIndex, authorisedBudgetOwners }
      }: PayloadAction<UpdateCategoryAubsByPosition>
    ) => {
      state.adminControls &&
        (state.adminControls.categories.items[
          entityCategoryIndex
        ].categories.items[categoryIndex].authorisedBudgetOwners.items =
          authorisedBudgetOwners)
      state.adminControls &&
        (state.adminControls.categories.items[
          entityCategoryIndex
        ].categories.items[categoryIndex].authorisedBudgetOwners.total =
          authorisedBudgetOwners.length)
    },
    setCategoryAllowByPosition: (
      state,
      {
        payload: { entityCategoryIndex, categoryIndex, allow }
      }: PayloadAction<UpdateCategoryAllowByPosition>
    ) => {
      state.adminControls &&
        (state.adminControls.categories.items[
          entityCategoryIndex
        ].categories.items[categoryIndex].allow = allow)
    },
    setCategorRulesByPosition: (
      state,
      {
        payload: { entityCategoryIndex, categoryIndex, rules }
      }: PayloadAction<UpdateCategoryRulesByPosition>
    ) => {
      state.adminControls &&
        (state.adminControls.categories.items[
          entityCategoryIndex
        ].categories.items[categoryIndex].rules.items = rules)
      state.adminControls &&
        (state.adminControls.categories.items[
          entityCategoryIndex
        ].categories.items[categoryIndex].rules.total = rules.length)
    }
  }
})

export const {
  setAdminControl,
  setRulesList,
  setBudgetOwnerControl,
  setCategoryByPosition,
  setCategoryAubByPosition,
  setCategorRulesByPosition,
  setCategoryAllowByPosition,
  setExpensePolicies
} = slice.actions
export default slice.reducer

export const selectAdminControls = (
  state: RootState
): AdminControlsDto | null => state.spendControls.adminControls

export const selectEntityCategory =
  (entityCategoryIndex: number) =>
    (state: RootState): EntityCategory =>
      state.spendControls.adminControls.categories.items[entityCategoryIndex]
export const selectCategory =
  (entityCategoryIndex: number, categoryIndex: number) =>
    (state: RootState): Category =>
      state.spendControls.adminControls.categories.items[entityCategoryIndex]
        .categories.items[categoryIndex]

export const selectExpensePolicies = (
  state: RootState
): ExpensePoliciesDto | null => state.spendControls?.expensePolicies

export const selectBudgetOwnerControls = (
  state: RootState
): BudgetOwnerControlsDto | null => state.spendControls.budgetOwnerControls


export const selectRulesList= (
  state: RootState
): RulesList | null => state.spendControls.ruleList
