import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import prepareHeaders from "network/util/prepare-headers"

import { VERIFICATION_API_BASE } from "./apiNamespaces"

export interface VerifyVerificationResponse {
  success: boolean
  code?: number
  data: {
    token: string
  }
}

export interface RequestVerificationResponse {
  success: boolean
  code?: number
}

export interface VerificationVerifyRequest {
  email: string
  otpCode: string
}

export interface VerificationRequest {
  username: string
  communicationType: number
}

export const verification = createApi({
  reducerPath: "verificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: VERIFICATION_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    requestVerification: builder.mutation<RequestVerificationResponse, VerificationRequest>({
      query: ({ username, communicationType }) => ({
        url: `create/${username}?communicationType=${communicationType}`,
        method: "POST"
      })
    }),
    verifyVerification: builder.mutation<VerifyVerificationResponse, VerificationVerifyRequest>({
      query: ({ email, otpCode }) => ({
        url: `verify/${email}/${otpCode}`,
        method: "PUT"
      })
    })
  })
})

export const {
  useRequestVerificationMutation,
  useVerifyVerificationMutation,
  reducer,
  middleware
} = verification
