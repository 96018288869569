export enum EventTypes {
  never = 0,
  CardActivated = 1,
  CardSuspended = 2,
  PinRequested = 3,
  CardClosed = 4,
  StatementReady = 5,
  WriteOff = 6,
  PaymentFailed = 7,
  ExpensePolicyApplied = 8,
  LimitApplied = 9,
  CardCreated = 10,
  CodatPlatFormConnected = 11,
  CodatPlatFormDisconnected = 12,
  BankAccountConfirmed = 13,
  BankAccountAlreadyExists = 14,
  BankAccountCreationFailed = 15,
  // NoActiveConnectionFound = 16,
  // SoldToFundingProvider = 17,
  ChartOfAccountsUpdated = 18,
  ConnectionDeletedInXero = 19,
  ConnectionError = 20,
  TransactionsSyncSuccessful = 21,
  TransactionsSyncNowCompleted = 22,
  ChartOfAccountsPullInitiated = 23,
  otherWithView = 99
}

export const getDisplayTitle = (type: EventTypes): string | undefined => {
  switch (type) {
  case EventTypes.StatementReady:
    return "Monthly Statement"

  case EventTypes.CardActivated:
    return "Card Activated"

  case EventTypes.CardClosed:
    return "Card Closed"

  case EventTypes.CardSuspended:
    return "Card Suspended"

  case EventTypes.PinRequested:
    return "Pin Request"

  case EventTypes.LimitApplied:
    return "Your limit has been changed"

  case EventTypes.ConnectionError:
  case EventTypes.ConnectionDeletedInXero:
    return "Bank feed connection error"

  default:
    return undefined
  }
}
