import { rest } from "msw"

import { usersSignUpCredentialsEndpoint, usersSignUpStartEndpoint } from "config/endpoints"
import { getStartBodyFx } from "mocks/fixtures/getStart"

export const endpoints = [
  rest.get(usersSignUpStartEndpoint(":guid"), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json(getStartBodyFx(true))
    )
  }),
  rest.post(usersSignUpCredentialsEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({ success: true })
    )
  })
]
