// type Tracker = {
//   key:boolean
//   sendConsoleErrors:boolean
//   tag:string
//   useDomainProxy:boolean
//   useUtfEncoding:boolean
// }

const LOGGLY_INPUT_PREFIX = "https://",
  // const LOGGLY_INPUT_PREFIX = "http" + (("https:" === document.location.protocol ? "s" : "")) + "://",
  LOGGLY_COLLECTOR_DOMAIN = "logs-01.loggly.com"
  // LOGGLY_SESSION_KEY = "logglytrackingsession"//,
  // LOGGLY_SESSION_KEY_LENGTH = LOGGLY_SESSION_KEY.length + 1 //,
  // LOGGLY_PROXY_DOMAIN = "loggly";

function uuid (): string {
  // lifted from here -> http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/2117523#2117523
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

function setKey(tracker: LogglyTracker, key: string): void {
  tracker.key = key
  tracker.setSession(null)
  setInputUrl(tracker)
}

function setTag(tracker: LogglyTracker, tag: string) {
  tracker.tag = tag
}

// function setDomainProxy(tracker:LogglyTracker, useDomainProxy:boolean) {
//  tracker.useDomainProxy = useDomainProxy;
//  //refresh inputUrl value
//  setInputUrl(tracker);
// }

function setUtfEncoding(tracker: LogglyTracker, useUtfEncoding: boolean){
  tracker.useUtfEncoding = useUtfEncoding
}

export type LogglyUnionData = ConsoleErrorData | LogData | LogglyStringData

type LogData = Record<string, unknown>|string
// type LogData = {
//   column: number
//   file: string
//   line: number
//   message: string
//   level?:string
// }

type ConsoleErrorData = {
  category: string
  exception: {
    message: Event | string
    url: string | undefined
    lineno: number | undefined
    colno: number | undefined
    stack: string | undefined
  }
}

function setSendConsoleError(tracker: LogglyTracker) {
  const _onerror = window.onerror
  // send console error messages to Loggly
  window.onerror = function (...args){
    const [msg, url, line, col, err] = args
    tracker.push({
      category: "BrowserJsException",
      exception: {
        message: msg,
        url: url,
        lineno: line,
        colno: col,
        stack: err ? err.stack : "n/a"
      }
    })

    if (_onerror && typeof _onerror === "function") {
      _onerror.apply(window, args)
    }
  }
}

function setInputUrl (tracker: LogglyTracker): void {
  // if (tracker.useDomainProxy == true) {
  //   tracker.inputUrl = LOGGLY_INPUT_PREFIX
  //     + window.location.host
  //     + "/"
  //     + LOGGLY_PROXY_DOMAIN
  //     + "/inputs/"
  //     + tracker.key
  //     + "/tag/"
  //     + tracker.tag;
  // } else {
  tracker.inputUrl = LOGGLY_INPUT_PREFIX
  + (tracker.logglyCollectorDomain || LOGGLY_COLLECTOR_DOMAIN)
  + "/inputs/"
  + tracker.key
  + "/tag/"
  + tracker.tag
  // }
}

export type LogglyOptionsData = {
  key: boolean
  session_id?: string
  sendConsoleErrors: boolean
  tag?: string
  // useDomainProxy:boolean
  useUtfEncoding: boolean
  inputUrl: string
  logglyCollectorDomain?: string
  logglyKey?: string
}

export type LogglyStringData = {
  text: string
}

export function isStringData(data: LogglyUnionData): data is string {
  return "string" === typeof data
}

// export function isLogglyStringData(data: LogglyUnionData): data is LogglyStringData {
//   return (data as LogglyStringData).text !== undefined
// }

// export function isConsoleErrorData(data: LogglyUnionData): data is ConsoleErrorData {
//   return ((data as ConsoleErrorData).category !== undefined) && ((data as ConsoleErrorData).exception !== undefined)
// }

export default class LogglyTracker {
  key: string|null
  session_id: boolean|string
  sendConsoleErrors: boolean
  tag: string
  // useDomainProxy:boolean
  useUtfEncoding: boolean
  inputUrl: string
  logglyCollectorDomain?: string

  constructor () {
    this.key = null
    this.sendConsoleErrors = false
    this.tag = "jslogger"
    // this.useDomainProxy = false;
    this.useUtfEncoding = false
    this.session_id = false
    this.inputUrl = ""
  }


  setSession(session_id: string|null): void {
    if (session_id) {
      this.session_id = session_id
      // this.setCookie(this.session_id);
    } else {
      this.session_id = uuid()
    }
  }

  config(options: LogglyOptionsData): void {
    if (options.logglyCollectorDomain) {
      this.logglyCollectorDomain = options.logglyCollectorDomain
    }

    if (options.sendConsoleErrors) {
      this.sendConsoleErrors = true
      setSendConsoleError(this)
    }

    if (options.tag) {
      setTag(this, options.tag)
    }

    setUtfEncoding(this, options.useUtfEncoding)

    // if (options.useDomainProxy) {
    //   setDomainProxy(this, options.useDomainProxy);
    // }

    if (options.logglyKey) {
      setKey(this, options.logglyKey)
    }

    if (options.session_id) {
      this.setSession(options.session_id)
    }

    // this.track(options);
  }

  push(data: LogglyUnionData): void {
    if (!this.key) {
      return
    }

    if (isStringData(data)) {
      this.track({ text: data })
    } else {
      this.track(data)
    }
  }

  track (data: LogglyUnionData, xmlHttp = new XMLHttpRequest()): void {
    // inject session id
    // data.sessionId = this.session_id;
    // console.log(data)

    try {
      // creating an asynchronous XMLHttpRequest
      xmlHttp.open("POST", this.inputUrl, true) // true for asynchronous request
      if (this.useUtfEncoding === true) {
        xmlHttp.setRequestHeader("Content-Type", "text/plain; charset=utf-8")
      } else {
        xmlHttp.setRequestHeader("Content-Type", "text/plain")
      }
      xmlHttp.send(JSON.stringify(data))

    } catch (ex) {
      console.log("Failed to log to loggly because of this exception:\n" + ex)
      console.log("Failed log data:", data)
    }
  }

  // readCookie ():boolean|string {
  //   const cookie = document.cookie,
  //     i = cookie.indexOf(LOGGLY_SESSION_KEY);
  //   if (i < 0) {
  //     return false;
  //   } else {
  //     let end = cookie.indexOf(";", i + 1);
  //     end = end < 0 ? cookie.length : end;
  //     return cookie.slice(i + LOGGLY_SESSION_KEY_LENGTH, end);
  //   }
  // }

  // setCookie (value:string|null):void {
  //   document.cookie = LOGGLY_SESSION_KEY + "=" + value;
  // }
}
