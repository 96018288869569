export interface ApiArrayData<T> {
  total: number;
  items: Array<T>
}

export default interface ApiResponse<T> {
  data: T;
  success: boolean;
  code: ResponseCode;
  message: string;
  details: string[];
  errors: Record<string, unknown>|null;
}

export enum ResponseCode {
  Ok = 0,
  AccountNumberNull = 1,
  PasswordNull = 2,
  PasswordTooShort = 3,
  PersonIdInvali = 4,
  EmailNull = 5,
  EmailInvalid = 6,
  UsernameNull = 7,
  UsernameInvali = 8,
  SaltNull = 9,
  SaltTooLon = 10,
  CreatedAtMissing = 11,
  UrlTooLon = 12,
  AmountInvalid = 13,
  PhoneNull = 14,
  CountryNull = 15,
  ErrorsInProcessing = 16,
  TokenReplacementFailed = 17,
  UserDtoNull = 18,
  UserIdInvalid = 19,
  UsernameExists = 20,
  UserNotFound = 21,
  EmailExists = 22,
  AddressLine1Null = 23,
  PostcodeNull = 24,
  CountryIdInvalid = 25,
  NoRepositoryProvided = 26,
  AddressIdInvalid = 27,
  IpAddressNull = 28,
  FirstNameNull = 29,
  LastNameNull = 30,
  RoleIdInvalid = 31,
  DuplicateUsers = 32,
  UserAgentNull = 33,
  CommunicationNotFound = 34,
  ContactTypeIdInvalid = 35,
  DescriptionNull = 36,
  CodeNull = 37,
  UriNull = 38,
  ExternalSlackService = 39,
  EmailDtoNull = 40,
  MessageIsNull = 41,
  NoRecipients = 42,
  SendGridClientError = 43,
  SubjectIsNull = 44,
  EntityNotFound = 45,
  CannotBeLessThan1 = 46,
  ExpiringLinkExpired = 47,
  IsLocked = 48,
  IsSuspended = 49,
  LoginDtoNull = 50,
  ExpiringLinkNotFound = 51,
  JwtTokenExpired = 52,
  JwtTokenIsNull = 53,
  ResetPassWordModelIsNull = 54,
  TokenNull = 55,
  UserPassInvalid = 56,
  ChangePasswordDtoNull = 57,
  PasswordTooWeak = 58,
  ContactMethodIdInvalid = 59,
  ExternalReferenceMissing = 60,
  AccountLocked = 61,
  AccountSuspended = 62,
  PasswordsDontMatch = 63,
  ValidationError = 64,
  TwoFactorRequested = 65,
  ArgumentNull = 66,
  VerificationPinWrong = 67,
  NoMobile = 68,
  TagNull = 69,
  DtoNull = 70,
  DtoInvalid = 71,
  NotFound = 72,
  DuplicateObject = 73,
  ForeignKey = 74,
  IdInvalid = 75,
  TokenLifetimeInvalid = 76,
  TokenFormatInvalid = 77,
  LoginViewModelIsNull = 78,
  JwtHeaderIsNull = 79,
  DisconnectViewModelIsNull = 80,
  EntityNotMatched = 81,
  AlreadyAdded = 82,
  SearchTermMissing = 83,
  DateRangeInvalid = 84,
  RegistrationNumberMissing = 85,
  UidMissing = 86,
  WrongState = 87,
  WrongType = 88,
  MandateCancelled = 89,
  MandateExpired = 90,
  MandateFailed = 91,
  RoleMissing = 92,
  AddressMissing = 93,
  UrlInvalid = 94,
  PhoneNumberIsInvalid = 95,
  BadPagination = 96,
  TypeMissing = 97,
  BankDetailsMissing = 98,
  WrongEnvironment = 99,
  NoFundsAllocated = 100,
  AllocationExceeded = 101,
  NoEmail = 102,
  ScopeMissing = 103,
  PinMissing = 104,
  PinExpired = 105,
  AlreadyVerified = 106,
  NoPrimaryContact = 107,
  ExpiringLinkCompleted = 108,
  ProviderMissing = 109,
  ProviderNotSupported = 110,
  CredentialsInvalid = 111,
}
