import { rest } from "msw"

import { personCardsEndpoint, personSummaryEndpoint, personCreateEndpoint } from "config/endpoints"
import { PersonFactory } from "mocks/factories/person"

import { States } from "../../models/enums/states"

const isOnBoardingUser = () => {
  const cookies = document.cookie.split("; ")
  const username = cookies.find(cookie => cookie.includes("username"))?.split("=")
  const usernameValue = username ? username[1] : ""
  return usernameValue.includes("onboard")
}

const isDDSetupDone = () => {
  const cookies = document.cookie.split("; ")
  const cookieString = cookies.find(cookie => cookie.includes("ddComplete"))?.split("=")
  const cookieValue = cookieString ? cookieString[1] : ""
  return cookieValue === "true"
}

const isDemoEnvironment = window && window.location.host.includes("getcape.io")

const personHandlers = [
  rest.post(personCreateEndpoint,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.set("Content-Type", "application/json"),
        ctx.json({
          success: true
        })
      )
    }),
  rest.get(
    personSummaryEndpoint,
    (req, res, ctx) => {

      const person = PersonFactory.build(isOnBoardingUser() && !isDDSetupDone() ? {
        addresses: [
          {
            id: 22,
            addressX: "WOTSO Manly 19-21 S Steyne Rd   Manly NSW 2095",
            organisation: "",
            department: "",
            address1: "WOTSO Manly",
            address2: "19-21 S Steyne Rd",
            address3: "",
            address4: "",
            address5: "",
            postTown: "Manly",
            countyField: "NSW",
            state: "",
            stateCode: "",
            postCode: "2095",
            countryId: 13,
            country: "Australia",
            externalReference: "",
            phone: "",
            startDate: "2022-06-24T00:00:00",
            endDate: "",
            char2Code: "AU",
            latitude: 0,
            longitude: 0,
            placeId: "",
            frankieReference: "",
            addressType: 1
          },
          {
            id: 25,
            addressX: "29 Morna point rd   Anna Bay NSW 2316",
            organisation: "",
            department: "",
            address1: "29",
            address2: "Morna point rd",
            address3: "",
            address4: "",
            address5: "",
            postTown: "Anna Bay",
            countyField: "NSW",
            state: "",
            stateCode: "",
            postCode: "2316",
            countryId: 13,
            country: "Australia",
            externalReference: "",
            phone: "",
            startDate: "2022-02-19T00:00:00",
            endDate: "",
            char2Code: "AU",
            latitude: 0,
            longitude: 0,
            placeId: "",
            frankieReference: "",
            addressType: 4
          }
        ],
        entity: {
          state: States.None
        }
      } : undefined)

      return res(
        ctx.status(200),
        ctx.set("Content-Type", "application/json"),
        ctx.json({
          success: true,
          data: person
        })
      )
    }
  )
]

if(isDemoEnvironment) {
  personHandlers.push(rest.get(
    personCardsEndpoint,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.set("Content-Type", "application/json"),
        ctx.json({
          "success": true,
          "data": {
            "total": 1,
            "items": [{
              "id": 15,
              "productId": 1,
              "internalLedgerId": 12,
              "entityId": 1,
              "personId": 5,
              "ledgerId": "6166e02e-3cfe-40dd-a43b-66603ecd834d",
              "cardType": 2,
              "type": 2,
              "externalReference": "61a86cd2-1dd6-49b3-b90e-185bbddef61e",
              "behaviours": 0,
              "status": isOnBoardingUser() ? 5 : 1,
              "carrierType": 3,
              "deliveryMethod": 2,
              "design": "CapeCredit",
              "programme": "Cape-Commercial-Credit-Physical",
              "name": "Ryan Pritchard",
              "deliveryName": "Mr Ryan Edwards-Pritchard",
              "telephone": "+61406252332",
              "refinement": "4",
              "number": "120",
              "street": "Bower Streeet",
              "city": "Manly",
              "region": "NSW",
              "isoCountry": "AU",
              "postalCode": "2095",
              "token": "152155462",
              "statusReason": null,
              "expiryDate": "11/24",
              "createdAt": "2021-12-02T06:50:58.868",
              "partnerProduct": 26,
              "truncatedPan": "4816",
              "transactions": null,
              "ledger": {
                "id": 12,
                "entityId": 1,
                "beneficiaryId": null,
                "mandateId": null,
                "partnerProduct": 31,
                "assetClass": 2,
                "assetType": 2,
                "assetOwner": 2,
                "creditDetailsId": null,
                "jurisdictionId": 13,
                "jurisdiction": "Australia",
                "jurisdictionChar2Code": "AU",
                "ledgerType": 2,
                "ledgerStatus": 2,
                "externalReference": "6166e02e-3cfe-40dd-a43b-66603ecd834d",
                "amount": isOnBoardingUser() ? 5000 : 1866.68,
                "bicSwift": null,
                "createdAt": "2021-10-13T13:33:34.266",
                "lastModifiedAt": "2021-10-13T13:33:34.266",
                "iban": null,
                "ibanStatus": null,
                "accountNumber": null,
                "ukAccountNumber": null,
                "ukSortCode": null,
                "routingNumber": null,
                "entityLedgers": [{
                  "id": 11,
                  "entityId": 1,
                  "code": "Cape",
                  "ledgerId": 12,
                  "ledgerDetails": "Ryan Edwards-Pritchard",
                  "externalReference": "6166e02e-3cfe-40dd-a43b-66603ecd834d",
                  "goCardlessReference": null,
                  "cards": [
                    {
                      "id": 9,
                      "ledgerId": 12,
                      "ledgerDetails": "Ryan Edwards-Pritchard",
                      "cardId": 9,
                      "cardDetails": "physical|Ryan Pritchard|2095|6166e49c-3eb2-4f0e-b624-a15d95f84183"
                    },
                    {
                      "id": 15,
                      "ledgerId": 12,
                      "ledgerDetails": "Ryan Edwards-Pritchard",
                      "cardId": 15,
                      "cardDetails": "physical|Ryan Pritchard|2095|61a86cd2-1dd6-49b3-b90e-185bbddef61e"
                    }
                  ],
                  "amount": isOnBoardingUser() ? 0 : 1866.68
                }],
                "ledgerCards": null,
                "transactions": null,
                "mandates": null,
                "goCardlessReference": null,
                "nature": 1,
                "type": 2,
                "limits": null,
                "limit": 5000,
                "balance": isOnBoardingUser() ? 0 : 3133.32,
                "statements": null,
                "accountName": "Ryan Edwards-Pritchard",
                "institutionName": null,
                "entries": null,
                "payments": null
              }
            }]
          }
        })
      )
    }
  ))
}

export const endpoints = personHandlers
