import { useCookies } from "react-cookie"
import { Navigate } from "react-router-dom"

import { ddSetup as ddSetupRoute, twoFAStart } from "components/layouts/Authorized/routes"
import { isUserHasState } from "components/util/isUserHasState"
import { FF__TWO_FA } from "config/environment"
import { Roles } from "models/enums/roles"
import { States } from "models/enums/states"
import Person from "models/person"


type RouteByPersonStateProps = {
  person: Person
  enableTwoFA?: boolean
  children: JSX.Element
}

export function RouteByPersonState({
  person,
  enableTwoFA = FF__TWO_FA,
  children
}: RouteByPersonStateProps): JSX.Element {
  const [cookies] = useCookies(["ddSkip"])

  const isUserWithoutBankAccoutSetup = !isUserHasState(person, States.BankAccountSetup)
  const primaryWithoutBankAccount = person.roles.find(r => r === Roles.Owner)
    && isUserWithoutBankAccoutSetup && !cookies["ddSkip"]
  const twoFa = !person.isTwoFactor && enableTwoFA

  if(primaryWithoutBankAccount) {
    return <Navigate
      to={{
        pathname: ddSetupRoute
      }}
    />
  }

  if (twoFa) {
    return <Navigate
      to={{
        pathname: twoFAStart
      }}
    />
  }

  return children
}
