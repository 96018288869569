import { useMemo } from "react"

import NotificationDto from "models/notification"
import { selectUnreadNotiCount, selectNotiTemplateData } from "store/slices/notification"

import { useAppSelector } from "."
export const useUnreadNotiCount = (): { unreadNotiCount: number } => {
  const unreadNotiCount = useAppSelector(selectUnreadNotiCount)
  return useMemo(() => ({ unreadNotiCount }), [unreadNotiCount])
}

export const useNotiTemplateData = (): { notiTemplateData: NotificationDto | undefined } => {
  const notiTemplateData = useAppSelector(selectNotiTemplateData)
  return useMemo(() => ({ notiTemplateData }), [notiTemplateData])
}
