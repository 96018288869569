export const account = "/account"
export const twoFA = "/2fa"
export const lockedAccount = "/account-locked"
export const tooManyCodes = "/too-many-codes"
export const creditLimitConfirm = (guid: string): string => `${account}/credit-limit-confirmation/${guid}`
export const setCredentials = (guid: string): string => `${account}/setcredentials/${guid}`
export const linkExpired = `${account}/expired`

export const login = "/login"
export const resetPassword = (guid: string): string => `/reset-password/${guid}`
export const sessionExpired = "/session-expired"

export const connectionAuthSuccess = "/connection-success"
export const connectionAuthFail = "/connection-fail"
