import { Box, Container, Flex, Text } from "native-base"

import { AXLE_BUILD, AXLE_VERSION } from "config/environment"

// import styles from "./Base.module.css"

const versionString = AXLE_VERSION
const appBuildString = AXLE_BUILD

interface BaseProps {
  header?: JSX.Element | JSX.Element[] | null
  content?: JSX.Element | JSX.Element[] | null
  footer?: JSX.Element | JSX.Element[] | null
  version?: string
  appBuild?: string
  bg?: string
}

export default function Base({
  header,
  content,
  footer,
  version = versionString,
  appBuild = appBuildString,
  bg = "white"
}: BaseProps): JSX.Element {
  return (
    <Box width={"100%"} height="100vh" maxWidth="1920px" margin="auto" bg={bg} testID="Base">
      <Container p="0px" maxWidth="100%" w="100%" bg={bg} zIndex="unset">
        {header}
        {appBuild && version && (
          <Flex width="100%" justify="center" align="center" pt="3.2px" position="absolute" top="2">
            <Text testID="build-string" fontSize="lg" color="gray.200">{`${appBuild} - ${version}`}</Text>
          </Flex>
        )}
      </Container>
      <Container p="0px" maxWidth="100%" bg={bg} position="initial">
        {content}
      </Container>
      <Container p="0px" maxWidth="100%" bg={bg}>
        {footer}
      </Container>
    </Box>
  )
}
