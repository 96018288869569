import {
  dashboard as dashboardRoute,
  transactions as transactionsRoute,
  myCards as myCardsRoute,
  payments as paymentRoute,
  people as peopleRoute,
  dashboardHelpCentre as dashboardHelpCentreRoute,
  settings as settingsRoute,
  budgets as budgetsRoute,
  receipts as receiptsRoute,
  expensesSegment as expensesRoute,
  spendControls as spendControlRoute
} from "components/layouts/Authorized/routes"
import EarPhoneIcon from "components/svgs/ear-phone"
import DashboardIcon from "components/svgs/home"
import MycardIcon from "components/svgs/my-card"
import PaymenyIcon from "components/svgs/payment"
import PeopleIcon from "components/svgs/people"
import SettingsIcon from "components/svgs/settings"
import TermsAndConditionsIcon from "components/svgs/t-and-c"
import TransactionIcon from "components/svgs/transactions"
import { FF__SPEND_CONTROLS, FF__BUDGETS, FF__RECEIPT_CAPTURE } from "config/environment"
import { Roles } from "models/enums/roles"
import { usePerson } from "store/hooks/person"

export interface btnLinkprops {
  testID: string
  path?: string
  icon?: JSX.Element
  text: string
  parentPath?: string
  subLinks?: btnLinkprops[]
}

const iconSizes = {
  width: "20px",
  height: "20px"
}

const peopleLink: btnLinkprops = {
  testID: "people-link",
  path: peopleRoute,
  icon: <PeopleIcon {...iconSizes} />,
  text: "People"
}

const dashboardLink: btnLinkprops = {
  testID: "dashboard-link",
  path: dashboardRoute,
  icon: <DashboardIcon {...iconSizes} />,
  text: "Dashboard"
}

const receiptsLink: btnLinkprops = {
  testID: "dashboard-receipts-link",
  path: receiptsRoute,
  text: "Receipts"
}

const spendControlLink: btnLinkprops = {
  testID: "dashboard-spendControls-link",
  path: spendControlRoute,
  text: "Spend Controls"
}
const transactionsLink: btnLinkprops = {
  testID: "dashboard-transactions-link",
  path: transactionsRoute,
  text: "Transactions"
}

const expensesLink: btnLinkprops = {
  testID: "dashboard-expenses-link",
  icon: <TransactionIcon {...iconSizes} />,
  text: "Expenses",
  parentPath: expensesRoute,
  subLinks: []
}

const myCardLink: btnLinkprops = {
  testID: "my-cards-link",
  path: myCardsRoute,
  icon: <MycardIcon {...iconSizes} />,
  text: "My Cards"
}

const paymentLink: btnLinkprops = {
  testID: "payment-link",
  path: paymentRoute,
  icon: <PaymenyIcon {...iconSizes} />,
  text: "Payments"
}

const helpCenterLink: btnLinkprops = {
  testID: "dashboard-help-center-link",
  path: dashboardHelpCentreRoute,
  icon: <EarPhoneIcon {...iconSizes} />,
  text: "Help Centre"
}

const settingLink: btnLinkprops = {
  testID: "settings-link",
  path: settingsRoute,
  icon: <SettingsIcon {...iconSizes} />,
  text: "Settings"
}

const budgetsLink: btnLinkprops = {
  testID: "budgets-link",
  path: budgetsRoute,
  icon: <TermsAndConditionsIcon {...iconSizes} />,
  text: "Budgets"
}

interface useDashboard {
  finalNavList: btnLinkprops[]
}

const useDashboard = ({ receiptCaptureEnabled }: { receiptCaptureEnabled: boolean }): useDashboard => {
  const { person } = usePerson()

  const navList = [dashboardLink]

  const userRoles = person ? [...person?.roles] : []

  const renderBudgetRoles = [Roles.Accountant, Roles.Administrator, Roles.BudgetOwner]

  if (FF__BUDGETS && renderBudgetRoles.some(role => userRoles.includes(role))) {
    navList.push(budgetsLink)
  }

  let finalNavList: btnLinkprops[] = []

  // Add all the iconlink required by role
  person &&
    userRoles
      .sort((a, b) => b - a)
      .forEach((role) => {
        if (role !== Roles.Administrator) {
          switch (role) {
          case Roles.Owner:
            expensesLink["subLinks"] = receiptCaptureEnabled && FF__RECEIPT_CAPTURE ? [transactionsLink, receiptsLink] : [transactionsLink]
            FF__SPEND_CONTROLS && expensesLink["subLinks"].push(spendControlLink)
            delete transactionsLink["icon"]
            finalNavList = [...navList, expensesLink, peopleLink, myCardLink, paymentLink]
            break

          case Roles.Customer:
            transactionsLink["icon"] = <TransactionIcon data-testid={"transaction-icon"} {...iconSizes} />
            finalNavList = [...navList, transactionsLink, myCardLink]
            break

          default:
            break
          }
        }
      })

  finalNavList = [...finalNavList, helpCenterLink, settingLink]

  return {
    finalNavList
  }
}

export default useDashboard
