import "./Dashboard.css"
import { Box, Flex, VStack } from "native-base"

import IconLink from "components/inline/IconLink"
import NavLink from "components/inline/IconLink/NavLink"
import SwitchProcessor from "components/inline/SwitchProcessor"
import AuthorizedLayout from "components/layouts/Authorized"
import { dashboard as dashboardRoute } from "components/layouts/Authorized/routes"
import LogoIcon from "components/svgs/logo"
import { FF__RECEIPT_CAPTURE } from "config/environment"
import { usePerson } from "store/hooks/person"


import StickyNav from "../../inline/StickyNav"

import BurgerNav from "./BurgerNav"
import useDashboard from "./talons/useDashboard"

export interface DashboardLayoutProps {
  children: JSX.Element | JSX.Element[] | null
  settingsPageDisabled?: boolean
  receiptCaptureEnabled?: boolean
}

const DashboardLayout = ({
  children,
  receiptCaptureEnabled = FF__RECEIPT_CAPTURE
}: DashboardLayoutProps): JSX.Element => {
  const { person } = usePerson()

  const { finalNavList } = useDashboard({ receiptCaptureEnabled })

  const topNavList = finalNavList.map((props) => <NavLink key={props.testID} {...props} mb="16px" />)

  return (
    <AuthorizedLayout burgerNav={<BurgerNav />} roles={person && person.roles}>
      <Flex flexDirection="row" width="100%">
        {/* Nav Menu  */}
        <Box
          testID="nav-links"
          w="230px"
          display={{
            base: "none",
            md: "flex"
          }}
        >
          <Flex
            position="fixed"
            h="100%"
            w="220px"
            top="0"
            left="0"
            pb="48px"
            pt="24px"
            flexDirection={{ base: "unset", md: "column" }}
            bg="primary.600"
          >
            {/* icon */}
            <Flex alignItems="center" mt={8}>
              <IconLink testID="logo" path={dashboardRoute} fontSize="83px" icon={<LogoIcon />} />
            </Flex>

            {/* Top Nav */}
            <Box mt="30px" mb="10px">
              <SwitchProcessor />
            </Box>

            <VStack
              testID="navigation-scroll-sidebar"
              flex="1"
              alignItems="flex-start"
              mt="5px"
              position="absolute"
              top="250px"
              height="calc(100vh - 320px)"
              overflowY="scroll"
            >
              {topNavList}
            </VStack>
          </Flex>
        </Box>

        {/* Main Content */}
        <Box testID="dashboard-hub" flex="1">
          <Box mt={{ base: "0", md: "48px" }} px={{ base: "2", md: "10" }} minH="100vh" paddingBottom="48px">
            {children}
          </Box>
          <StickyNav roles={person && person.roles} />
        </Box>
      </Flex>
    </AuthorizedLayout>
  )
}

export default DashboardLayout
