import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react"

import EntityUsageDto from "models/entity"
import EntityPersonDto from "models/entityPerson"
import { Processor } from "models/enums/processor"
import { MandateModel } from "models/mandate"
import prepareHeaders from "network/util/prepare-headers"


import { ENTITY_API_BASE } from "./apiNamespaces"
import ApiResponse, { ApiArrayData } from "./util/api-response"

export interface AddABankAccountOrDD {
  Uid?: string
  AccountHolderName: string
  AccountNumber: string
  SortCode: string
  EmailAddress: string
  MultiSignatoryAccount: boolean
}

export interface MakeAPayment {
  Uid?: string
  Amount: number
  ChargeDate: string
  Reference: string
  Description: string
}

export interface GetPersonsList {
  page: number
  pageSize: number
  searchTerm?: string
  processor: Processor
}

const retryWellBaseQuery = retry(
  fetchBaseQuery({ baseUrl: ENTITY_API_BASE, prepareHeaders }),
  {
    maxRetries: 3
  }
)

export const entityApi = createApi({
  reducerPath: "entityApi",
  baseQuery: retryWellBaseQuery,
  endpoints: (builder) => ({
    BankAccount: builder.mutation<
      ApiResponse<MandateModel>,
      AddABankAccountOrDD
    >({
      query: (data) => ({
        url: "/BankAccount",
        method: "POST",
        body: data
      }),
      extraOptions: { maxRetries: 0 }
    }),
    Mandate: builder.mutation<ApiResponse<never>, AddABankAccountOrDD>({
      query: (data) => ({
        url: "/Mandate",
        method: "POST",
        body: data
      }),
      extraOptions: { maxRetries: 0 }
    }),
    Payment: builder.mutation<ApiResponse<never>, MakeAPayment>({
      query: (data) => ({
        url: "/Payment",
        method: "POST",
        body: data
      }),
      extraOptions: { maxRetries: 0 }
    }),
    Usage: builder.mutation<ApiResponse<EntityUsageDto>, void>({
      query: () => ({
        url: "/usage",
        method: "GET"
      }),
      extraOptions: { maxRetries: 0 }
    }),
    Persons: builder.mutation<
      ApiResponse<ApiArrayData<EntityPersonDto>>,
      GetPersonsList | void
    >({
      query: (data) => ({
        url: "/persons",
        method: "POST",
        body: data ?? {
          page: 0,
          pageSize: 10
        }
      }),
      extraOptions: { maxRetries: 0 }
    })
  })
})

export const {
  useBankAccountMutation,
  useMandateMutation,
  usePaymentMutation,
  useUsageMutation,
  usePersonsMutation,
  reducer,
  middleware
} = entityApi
