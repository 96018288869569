import {
  CARD_API_BASE,
  ENTITY_API_BASE,
  BUDGETS_API_BASE,
  EVENTS_API_BASE,
  EXPENSE_API_BASE,
  NOTIFICATION_API_BASE,
  NOTIFICATIONS_API_BASE,
  OAUTH_API_BASE,
  PERSON_API_BASE,
  SIGN_UP_API_BASE,
  RECEIPTS_API_BASE,
  TRANSACTIONS_API_BASE,
  GPS_TRANSACTIONS_API_BASE,
  USERS_API_BASE,
  STATEMENT_API_BASE,
  VERIFICATION_API_BASE,
  LIMITS_API_BASE
} from "network/apiNamespaces"

import { stringToBase64 } from "../components/util/base64"

export const capeWWW = "https://www.getcape.io"

const xeroPlatFormType = "gbol"

// events
export const eventsEndpoint = EVENTS_API_BASE

// budgets
export const budgetsEndpoint = `${BUDGETS_API_BASE}`
export const spendControlsEndpoint = `${ENTITY_API_BASE}/rules`

// signup
export const usersSignUpCredentialsEndpoint = `${SIGN_UP_API_BASE}/credentials`
export const usersSignUpStartEndpoint = (guid: string): string => `${SIGN_UP_API_BASE}/start/${guid}`

// oauth
export const oauthEndpoint = (base64AuthCode: string, guid: string, provider: string): string =>
  `${OAUTH_API_BASE}/${base64AuthCode}/${guid}/${provider}`

// statemnt
export const statementEndpoint = (statementId: string): string => `${STATEMENT_API_BASE}/detail/${statementId}`

// user
export const usersLoginEndpoint = `${USERS_API_BASE}/login`
export const usersStartLoginEndpoint = `${USERS_API_BASE}/start`
export const usersStartLoginEmailEndpoint = (email: string): string =>
  `${usersStartLoginEndpoint}/${stringToBase64(email)}`
export const usersRefreshEndpoint = `${USERS_API_BASE}/refresh`
export const userResetPasswordEndpoint = `${USERS_API_BASE}/resetpassword`
export const userChangePasswordEndpoint = `${USERS_API_BASE}/changepassword`
export const userSetPasswordEndpoint = `${USERS_API_BASE}/setpassword`
export const userSet2FA = (communicationType: number) => `${USERS_API_BASE}/set2fa/${communicationType}`

// person
export const personCreateEndpoint = `${PERSON_API_BASE}/create`
export const personIsUniqueEndpoint = `${PERSON_API_BASE}/isunique`
export const personDetailEndpoint = `${PERSON_API_BASE}/detail`
export const personCardsEndpoint = `${PERSON_API_BASE}/Cards`
export const personSummaryEndpoint = `${PERSON_API_BASE}/summary`
export const personUpdateAddress = `${PERSON_API_BASE}/updateAddress`
export const personUpdate = `${PERSON_API_BASE}/update`

// receipts
export const addReceipt = (transactionID: number): string => `${RECEIPTS_API_BASE}/addReceipt/${transactionID}`
export const addReceiptNote = `${RECEIPTS_API_BASE}/addMemo`
export const removeReceiptNote = (blobID: number): string => `${RECEIPTS_API_BASE}/deleteMemo/${blobID}`
export const getReceipt = `${RECEIPTS_API_BASE}/receipt`
export const removeReceipt = (id: number) => `${RECEIPTS_API_BASE}/deleteReceipt/${id}`
export const addTag = `${RECEIPTS_API_BASE}/addTag`
export const linkTag = `${RECEIPTS_API_BASE}/linkTag`
export const unLinkTag = (tagID: number) => `${RECEIPTS_API_BASE}/unlinkTag/${tagID}`
export const getReceiptImage = (id: number) => `${RECEIPTS_API_BASE}/receipt/${id}`

// card
export const cardOrderEndpoint = `${CARD_API_BASE}/order`
export const cardReplaceEndpoint = `${CARD_API_BASE}/replace`
export const cardDetailEndpoint = (cardId: number): string => `${CARD_API_BASE}/detail/${cardId}`
export const cardActivateEndpoint = `${CARD_API_BASE}/activate`
export const cardPinEndpoint = `${CARD_API_BASE}/pin`
export const cardSuspendEndpoint = (cardId: number): string => `${CARD_API_BASE}/suspend/${cardId}`

// connections
export const connectionEndpoint = `${EXPENSE_API_BASE}/platform/${xeroPlatFormType}/connect`
export const disconnectionEndpoint = `${EXPENSE_API_BASE}/platform/disconnect`
export const connectionsEndpoint = `${EXPENSE_API_BASE}/platforms`
export const xeroAccountsEndpoint = `${EXPENSE_API_BASE}/platform/${xeroPlatFormType}/accounts`
export const xeroSyncEndpoint = `${EXPENSE_API_BASE}/platform/${xeroPlatFormType}/sync`
export const xeroAccountEndpoint = `${EXPENSE_API_BASE}/platform/${xeroPlatFormType}/account`
export const xeroAccountCreateEndpoint = `${EXPENSE_API_BASE}/platform/${xeroPlatFormType}/account`

// transasctions
export const transactionsEndpoint = `${TRANSACTIONS_API_BASE}`
export const transactionsAccessTokenEndpoint = `${TRANSACTIONS_API_BASE}/accessToken`
export const transactionsExportEndpoint = `${TRANSACTIONS_API_BASE}/export`

// gps transasctions
export const gpsTransactionsEndpoint = `${GPS_TRANSACTIONS_API_BASE}`
export const gpsPendingTransactionsEndpoint = `${GPS_TRANSACTIONS_API_BASE}/pending`
export const gpsTransactionsAccessTokenEndpoint = `${GPS_TRANSACTIONS_API_BASE}/accessToken`
export const gpsTransactionsExportEndpoint = `${GPS_TRANSACTIONS_API_BASE}/export`
export const getReceiptsEndpoint = `${GPS_TRANSACTIONS_API_BASE}/receipts`
export const gpsTransactinsReciptsImageDownload = `${GPS_TRANSACTIONS_API_BASE}/downloadreceipts`
export const gpsTransactinsReciptsCSVDownload = `${GPS_TRANSACTIONS_API_BASE}/exportreceipts`
export const gpsTransactionsGetTransaction = (transactionId: number) =>
  `${GPS_TRANSACTIONS_API_BASE}/details/${transactionId}`
export const gpsTransationsGetTags = `${GPS_TRANSACTIONS_API_BASE}/tags`
export const gpsGetTranscation = (transactionId: number) => `${GPS_TRANSACTIONS_API_BASE}/details/${transactionId}`
export const getReceiptsDownloadOTCToken = `${GPS_TRANSACTIONS_API_BASE}/accessToken`
export const fakeFileStream = `${GPS_TRANSACTIONS_API_BASE}/fakeStream`
export const getTags = `${GPS_TRANSACTIONS_API_BASE}/tags`

// notifications
export const notificationsEndpoint = `${NOTIFICATIONS_API_BASE}`
export const notificationsMarkReadEndpoint = `${NOTIFICATIONS_API_BASE}/markread`
export const unreadNotiCountEndpoint = `${NOTIFICATIONS_API_BASE}/unreadCount`

// notification
export const readNotificationEndpoint = (eventId: number) => `${NOTIFICATION_API_BASE}/markread/${eventId}`

// entity
export const makePaymentEndpoint = `${ENTITY_API_BASE}/Payment`
export const entityDetailEndpoint = `${ENTITY_API_BASE}/Detail`
export const entityBankAccountEndpoint = `${ENTITY_API_BASE}/BankAccount`
export const entityMandateEndpoint = `${ENTITY_API_BASE}/Mandate`
export const entityUsageEndpoint = `${ENTITY_API_BASE}/usage`
export const entityPersonsEndpoint = `${ENTITY_API_BASE}/persons`

// verifications
export const verificationsVerifyEndpoint = (email: string, otp: string) =>
  `${VERIFICATION_API_BASE}/verify/${email}/${otp}`
export const verificationCreateEndpoint = (username: string) => `${VERIFICATION_API_BASE}/create/${username}`

// limits
export const limitsEndpoint = `${LIMITS_API_BASE}`

export const logglyEndpoint = "https://logs-01.loggly.com"
