import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import BlobDto from "models/blob"
import { GpsTransactionDto as Transaction } from "models/gpsTransaction"
import TagDto from "models/tag"
import prepareHeaders from "network/util/prepare-headers"

import { GPS_TRANSACTIONS_API_BASE } from "./apiNamespaces"
import ApiResponse, { ApiArrayData } from "./util/api-response"

export interface GetTransactionsRequest {
  LedgerId: number,
  Page: number;
  axleOnly: boolean;
  PageSize: number;
  SearchTerm: string | null;
  From: string | null;
  To: string | null;
  hasReceipts: boolean | null
}

export interface Data {
  total: number;
  items: Transaction[];
}

export interface Otc {
  success: boolean
  data: {
    accessToken: string
  }
}

export interface GetTransactionsResponse {
  success: boolean;
  data: Data;
}

export const transactions = createApi({
  reducerPath: "gpsTransactionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GPS_TRANSACTIONS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getTransactions: builder.mutation<
      ApiResponse<ApiArrayData<Transaction>>,
      GetTransactionsRequest
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    }),
    getPendingTransactions: builder.mutation<
      ApiResponse<ApiArrayData<Transaction>>,
      GetTransactionsRequest
    >({
      query: (data) => ({
        url: "pending",
        method: "POST",
        body: data
      })
    }),
    getOTC: builder.mutation<Otc, void>({
      query: () => ({
        url: "accessToken",
        method: "POST"
      })
    }),
    getTransaction: builder.mutation<
      {
        success: boolean,
        data: Transaction
      },
      {
        transactionId: number
      }
    >({
      query: ({ transactionId }) => ({
        url: `/details/${transactionId}`,
        method: "GET"
      })
    }),
    getReceipts: builder.mutation<
      {
        success: boolean,
        data: {
          total: number,
          items: BlobDto[]
        }
      },
      {
        username?: string | null,
        ledgerId?: number | null,
        page?: number,
        pageSize?: number,
        searchTerm?: string | null,
        from?: string | null,
        to?: string | null,
        tags?: string[] | null
      }
    >({
      query: (data) => ({
        url: "/receipts",
        method: "POST",
        body: data
      })
    }),
    getTags: builder.mutation<
      {
        success: boolean,
        data: {
          items: TagDto[]
          total: number
        }
      },
      {
        page: number,
        pageSize: number,
        searchTerm: string,
        entityId: number
      }
    >({
      query: (data) => ({
        url: "/tags",
        method: "POST",
        body: data
      })
    })
  })
})

export const {
  useGetTransactionsMutation,
  useGetPendingTransactionsMutation,
  useGetOTCMutation,
  useGetReceiptsMutation,
  useGetTransactionMutation,
  useGetTagsMutation,
  reducer,
  middleware
} = transactions
