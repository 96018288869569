import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import prepareHeaders from "network/util/prepare-headers"

import { NOTIFICATION_API_BASE } from "./apiNamespaces"

export interface SetReadNotificationRequest {
  eventId: number
}

export interface SetReadNotificationResponse {
  success: boolean
}

export const notification = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: NOTIFICATION_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    setReadNotification: builder.mutation<SetReadNotificationResponse, SetReadNotificationRequest>({
      query: ({ eventId }) => ({
        url: `/markread/${eventId}`,
        method: "PUT"
      })
    })
  })
})

export const { useSetReadNotificationMutation, reducer, middleware } = notification
