import { CookieStorage } from "cookie-storage"

export default function prepareHeaders(headers: Headers, { endpoint }: { endpoint: string }): Headers {
  const cookieStorage = new CookieStorage()
  const jwt = cookieStorage.getItem("jwt")
  const username = cookieStorage.getItem("username")

  if (jwt) {
    headers.set("Authorization", `Bearer ${jwt}`)
  }

  if (username) {
    headers.set("username", username)
  }

  if(!endpoint.includes("addReceipt")) {
    headers.set("Content-Type", "application/json")
  }

  return headers
}

