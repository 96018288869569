// import { Processor } from "models/enums/processor"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Platform } from "models/platform"

import type { RootState } from "store"
export type PlatformState = Platform[]

interface UpdateConnectionsStatusPayload {
  connectionIndex: number | null,
  connectionStatus: string
}

const slice = createSlice({
  name: "platforms",
  initialState: [] as PlatformState,
  reducers: {
    addPlatforms: (state, { payload }: PayloadAction<PlatformState>) => (
      state = payload
    ),
    updateConnectionsStatus: (
      state,
      { payload: { connectionIndex, connectionStatus } }: PayloadAction<UpdateConnectionsStatusPayload>
    ) => { state[connectionIndex || 0].name = connectionStatus }
  }
})

export const { updateConnectionsStatus, addPlatforms } = slice.actions
export default slice.reducer
export const fetchPlatforms = (state: RootState): Platform[]|null => {
  return state.platforms
}
