import { Row, Column, Button, Text, Input, Popover, Heading, Pressable, Spacer } from "native-base"

import InfoIcon from "components/svgs/info"
import { Processor } from "models/enums/processor"
import { useAppDispatch } from "store/hooks"
import { useLedgers } from "store/hooks/ledgers"
import { setSelectedProcessor } from "store/slices/ledgers"

export default function ({
  colorMode = "light",
  callback
}: {
  colorMode?: "dark" | "light"
  callback?: (open: boolean) => void
}): JSX.Element {
  const {
    ledgerState: { selectedProcessor, mode }
  } = useLedgers()
  const dispatch = useAppDispatch()

  const selectRails = () => {
    dispatch(setSelectedProcessor(Processor.Rails))
    callback && callback(false)
  }
  const selectGps = () => {
    dispatch(setSelectedProcessor(Processor.Gps))
    callback && callback(false)
  }

  const isRails = selectedProcessor === Processor.Rails
  const isGps = selectedProcessor === Processor.Gps

  if (mode === "single") return <></>

  return (
    <Column alignSelf="center" justifyContent="center" space="1">
      <Row alignItems="center" ml="3" mb="1">
        <Heading mr="1" color={colorMode === "light" ? "tertiary.50" : "tertiary.700"} size="sm">
          Select card
        </Heading>
        <Popover
          placement="right"
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>
                <InfoIcon  fill={colorMode === "light" ? "#fff" : "#57534E"} />
              </Pressable>
            )
          }}
        >
          <Popover.Content accessibilityLabel="Processor toggle" w="56">
            <Popover.Arrow />
            <Popover.CloseButton />
            <Popover.Header>What does this mean?</Popover.Header>
            <Popover.Body>
              <Text>We’re moving to a new card processor to improve your experience with Cape.</Text>
              <Spacer h="2" />
              <Text>To do this, we need to you switch to using a new Cape card that we’ve sent you.</Text>
              <Spacer h="2" />
              <Text>For a brief period, you’ll be able to see transactions for your old card, and your new card when you login. Use this menu to switch between them.</Text>
            </Popover.Body>
          </Popover.Content>
        </Popover>
      </Row>
      <Row>
        <Button.Group borderRadius="100" testID="switch-processor" isAttached size="lg">
          <Button
            bg={isGps ? "tertiary.50" : "tertiary.200"}
            variant={isGps ? "outline" : "primary"}
            h="8"
            borderColor="tertiary.200"
            _hover={{ bg: isGps ? "tertiary.50" : "tertiary.200", borderColor: "tertiary.200" }}
            testID="select-gps"
            onPress={selectGps}
          >
            <Text color={isGps ? "tertiary.800" : "tertiary.700"}>New Card</Text>
          </Button>
          <Button
            bg={isRails ? "tertiary.50" : "tertiary.200"}
            variant={isRails ? "outline" : "primary"}
            h="8"
            borderColor="tertiary.200"
            _hover={{ bg: isRails ? "tertiary.50" : "tertiary.200", borderColor: "tertiary.200" }}
            testID="select-rails"
            onPress={selectRails}
          >
            <Text color={isRails ? "tertiary.800" : "tertiary.700"}>Old Card</Text>
          </Button>
        </Button.Group>
        <Input testID="selected-processor" value={isRails ? "rails" : "gps"} type="text" display="none" />
      </Row>
    </Column>
  )
}
