import { useMemo } from "react"

import { useAppSelector } from "store/hooks"
import { selectCurrentOpenReplay, OpenReplayState } from "store/slices/openReplay"

export const useCurrentOpenReplay = (): OpenReplayState => {
  const openReplay = useAppSelector(selectCurrentOpenReplay)

  return useMemo(() => (openReplay), [openReplay])
}
