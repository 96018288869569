import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import NotificationDto from "models/notification"

import type { RootState } from "store"
export type NotificationState = {
  unreadCount: number
  templateData: NotificationDto | undefined
}

const slice = createSlice({
  name: "notification",
  initialState: {
    unreadCount: 0,
    templateData: undefined
  } as NotificationState,
  reducers: {
    setUnreadNotiCount: (state, { payload: unreadCount }: PayloadAction<number>) => ({ ...state, unreadCount })
  }
})

export const { setUnreadNotiCount } = slice.actions
export default slice.reducer

export const selectUnreadNotiCount = (state: RootState): number => {
  return state.notification.unreadCount
}

export const selectNotiTemplateData = (state: RootState): NotificationDto | undefined => {
  return state.notification.templateData
}
