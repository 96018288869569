import { Flex, Image, useBreakpointValue } from "native-base"

import logo from "assets/svgs/public-logo.svg"

import BaseLayout from "../Base"

interface IPublicProps {
  children?: JSX.Element
  header?: JSX.Element
  footer?: JSX.Element
}

const Public = ({ children, header, footer }: IPublicProps): JSX.Element => {
  const flexJustifyContent = useBreakpointValue({ base: "center", sm: "flex-start" })
  const flexAlignItems = useBreakpointValue({ base: "center", sm: "flex-start" })
  const imageSize = useBreakpointValue({ base: "80px", sm: "99px" })

  return (
    <BaseLayout
      bg="tertiary.50"
      header={
        header || (
          <Flex
            p="5vh"
            pt="5vh"
            pb="1vh"
            justifyContent={flexJustifyContent}
            alignItems={flexAlignItems}
            h="100%"
            width="100%"
          >
            <Image size={imageSize} resizeMode="contain" testID="Public" src={logo} alt="logo" />
          </Flex>
        )
      }
      content={children}
      footer={footer}
    />
  )
}

export default Public
