
import { useMemo } from "react"

import { AddressTypes } from "models/enums/address-types"
import { useAppSelector } from "store/hooks"
import { selectFirstAddress, selectFirstBankAccount, selectPerson } from "store/slices/person"

import type AddressDto from "models/address"
import type LedgerDto from "models/ledger"
import type Person from "models/person"

export const usePerson = (): { person: Person } => {
  const person = useAppSelector(selectPerson)
  return useMemo(() => ({ person }), [person])
}

export const usePersonBankAccount = (): { account: LedgerDto|null } => {
  const account = useAppSelector(selectFirstBankAccount)

  return useMemo(() => ({ account }), [account])
}

export const usePersonHomeAddress = (): { address: AddressDto|null } => {
  const address = useAppSelector(selectFirstAddress(AddressTypes.Home))

  return useMemo(() => ({ address }), [address])
}

export const usePersonRegisteredAddress = (): { address: AddressDto|null } => {
  const address = useAppSelector(selectFirstAddress(AddressTypes.Registered))

  return useMemo(() => ({ address }), [address])
}
