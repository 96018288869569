import * as React from "react"
import { Svg, SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 22 24"
    {...props}
  >
    <Path
      clipRule="evenodd"
      d="M7.848 2.994C7.912 1.365 9.326 0 11 0c1.679 0 3.095 1.373 3.153 3.008 3.499 1.296 5.976 4.581 5.976 8.494v3.659c1.09.452 1.871 1.526 1.871 2.741a2.977 2.977 0 0 1-3.01 2.97H3.01c-1.642 0-3.01-1.36-3.01-2.97a2.97 2.97 0 0 1 1.871-2.754v-3.646a9.021 9.021 0 0 1 5.977-8.508ZM11 2.24c-.445 0-.876.418-.876.871v.711c0 .514-.355.961-.862 1.086-2.94.727-5.112 3.401-5.112 6.594v4.551c0 .619-.51 1.12-1.14 1.12a.718.718 0 0 0-.732.73c0 .381.36.728.733.728h15.978a.718.718 0 0 0 .733-.729c0-.382-.36-.729-.733-.729a1.13 1.13 0 0 1-1.139-1.12v-4.55c0-3.178-2.16-5.804-5.134-6.6a1.123 1.123 0 0 1-.84-1.08V3.11c0-.453-.43-.871-.876-.871Z"
    />
    <Path
      clipRule="evenodd"
      d="M9.326 20.942h3.348c-.355.496-.951.818-1.674.818s-1.32-.322-1.674-.818Zm-1.493-2.24v2.24-1.12h-1.14C6.694 22.16 8.584 24 11 24c2.417 0 4.306-1.84 4.306-4.178 0-.618-.51-1.12-1.139-1.12H7.833Z"
    />
    <Path
      clipRule="evenodd"
      d="M12.674 20.942H9.326c.355.496.951.818 1.674.818s1.32-.322 1.674-.818Zm1.493-2.24v2.24-1.12h1.14C15.306 22.16 13.416 24 11 24c-2.417 0-4.306-1.84-4.306-4.178 0-.618.51-1.12 1.14-1.12h6.333Z"
    />
  </Svg>
)

export default SvgComponent
