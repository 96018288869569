export default {
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ["48px", "72px"],
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "60.72px",
      fontFamily: "CircularStdBlack"
    },
    h2: {
      // you can also use responsive styles
      fontSize: ["24px", "48px"],
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "60.72px",
      fontFamily: "CircularStdMedium"
    }
  },
  colors: {
    yellow: {
      100: "#FFF4C6",
      200: "#FFEECC"
    },
    red: {
      50: "#FCE8E9",
      100: "#F7C0C0",
      200: "#F29798",
      300: "#EC696A", // #EC696A (Hot coral)
      400: "#E74547",
      500: "#E21D1E",
      600: "#B51718",
      700: "#881112",
      800: "#5B0B0C",
      900: "#2D0606"
    },
    gray: {
      50: "#F3F3F3", // #F3F3F3 (Pale grey)
      100: "#E5E5E5", // #E5E5E5 (Warm grey)
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#979797", // #979797 (Space grey)
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A"
    },
    blue: {
      50: "#e6f3ff",
      100: "#c4d7ee",
      200: "#a1bcde",
      300: "#7ca1d0",
      400: "#5886c0",
      500: "#3f6da8",
      600: "#305483",
      700: "#223E61", // #223E61 (Cape card) is the same
      800: "#11243b",
      900: "#020c19"
    },

    green: {
      50: "#68ed8e4d",
      100: "#19CEAF"
    },

    // new theme color
    primary: {
      50: "#F5FBFF",
      100: "#D8EEFF",
      200: "#BBE2FF",
      300: "#9ED6FF",
      400: "#87BEE6",
      500: "#70A3C9",
      600: "#5A89AC",
      700: "#46708F",
      800: "#345772",
      900: "#244055"
    },
    secondary: {
      50: "#FFF4F5",
      100: "#FFD5D8",
      200: "#FCB4B8",
      300: "#F59197",
      400: "#D87B80",
      500: "#BB666B",
      600: "#9E5257",
      700: "#804044",
      800: "#632F32",
      900: "#462022"
    },
    tertiary: {
      50: "#FBFAF6",
      100: "#E7E5DE",
      200: "#D2D0C7",
      300: "#BEBBB1",
      400: "#A9A79B",
      500: "#959286",
      600: "#817E72",
      700: "#6C695E",
      800: "#58554B",
      900: "#434138"
    },
    warmGray: {
      50: "#FAFAF9",
      100: "#F5F5F4",
      200: "#E7E5E4",
      300: "#D6D3D1",
      400: "#A8A29E",
      500: "#78716C",
      600: "#57534E",
      700: "#44403C",
      800: "#292524",
      900: "#1C1917"
    },
    warning: {
      400: "#FB923C"
    }
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "GTPressuraPro",
        fontWeight: "500",
        _light: {
          color: "warmGray.600"
        }
      }
    },
    Text: {
      baseStyle: {
        fontFamily: "CircularStdBook",
        _light: {
          color: "warmGray.600"
        }
      }
    },
    FormControlLabel: {
      baseStyle: {
        _text: {
          fontFamily: "CircularStdMedium",
          color: "warmGray.600",
          fontSize: "md"
        }
      }
    },
    Pressable: {
      baseStyle: {
        fontFamily: "CircularStdBook"
      }
    },
    Link: {
      baseStyle: {
        // the rest for NativeBase
        _text: {
          fontFamily: "CircularStdMedium",
          color: "warmGray.600",
          textDecorationLine: "none",
          fontSize: "14px"
        },
        _hover: {
          _text: {
            color: "primary.600"
          },
          textDecorationLine: "underline",
          textDecorationColor: "primary.600"
        }
      }
    },
    Input: {
      baseStyle: {
        width: "100%",
        height: "48px",
        backgroundColor: "white",
        bg: "white",
        background: "white",
        borderRadius: "8px",
        "border-radius": "8px",
        _light: {
          _focus: {
            borderColor: "primary.600",
            boxShadow: "primary.600 0px 0px 0px 1px",
            bg: "none",
            _hover: {
              borderColor: "primary.600"
            },
            _stack: {
              style: {
                outlineColor: "primary.600"
              }
            }
          },
          _hover: {
            borderColor: "gray.300"
          }
        },
        field: {
          backgroundColor: "white",
          bg: "white",
          background: "white",
          borderRadius: "8px",
          "border-radius": "20px"
        }
      },
      defaultProps: {
        fontSize: "16px",
        borderRadius: "8px",
        focusBorderColor: "primary.600"
      },
      variants: {
        review: {
          _text: {
            color: "warmGray.600"
          },
          field: {
            color: "warmGray.600",
            borderWidth: "1px",
            borderColor: "warmGray.600",
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
            "border-radius": 0
          }
        }
      }
    },
    TextArea: {
      defaultProps: {
        fontSize: "16px",
        borderRadius: "8px",
        focusBorderColor: "primary.600"
      }
    },
    Select: {
      baseStyle: {
        field: {
          backgroundColor: "white",
          bg: "white",
          background: "white",
          borderRadius: "8px",
          "border-radius": "8px"
        }
      },
      defaultProps: {
        borderRadius: "8px",
        "border-radius": "8px",
        focusBorderColor: "primary.600"
      }
    },
    FormLabel: {
      baseStyle: {
        _text: {
          fontFamily: "CircularStdMedium",
          color: "warmGray.600",
          fontSize: "md"
        }
      }
    },

    Container: {
      baseStyle: {
        zIndex: "revert"
      }
    },
    Button: {
      baseStyle: {
        bg: "tertiary.800",
        py: "8px",
        height: "fit-content",
        fontFamily: "GTPressuraPro",
        fontWeight: "normal",
        letterSpacing: "0px",
        background: "tertiary.800",
        borderRadius: "8px",
        color: "white",
        fontSize: "18px",
        _hover: {
          color: "white",
          backgroundColor: "tertiary.700",
          bg: "tertiary.700",
          background: "tertiary.700"
        },
        _text: {
          fontSize: "18px"
        },
        _focus: {
          boxShadow: "unset"
        }
      },
      variants: {
        solid: {
          _text: {
            fontSize: "18px"
          },
          _hover: {
            _text: {
              color: "white"
            }
          },
          _pressed: {
            _text: {
              color: "white"
            }
          }
        },
        burger: {
          backgroundColor: "transparent",
          bg: "transparent",
          background: "transparent",
          borderColor: "transparent",
          "border-color": "transparent",
          color: "transparent",
          width: "3rem",
          _hover: {
            color: "transparent"
          }
        },
        outline: {
          backgroundColor: "tertiary.50",
          bg: "tertiary.50",
          background: "transparent",
          borderColor: "tertiary.800",
          "border-color": "tertiary.800",
          color: "warmGray.600",
          _text: {
            color: "tertiary.800",
            fontFamily: "GTPressuraPro"
          },
          borderWidth: "2px",
          _hover: {
            backgroundColor: "tertiary.50",
            bg: "transparent",
            background: "transparent",
            color: "primary.700",
            borderColor: "primary.700",
            _text: {
              color: "primary.700"
            }
          }
        }
      }
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: "white",
            borderColor: "blue.300",
            color: "blue.300",
            _hover: {
              bg: "white",
              borderColor: "blue.300",
              color: "blue.300",
              boxShadow: "0 0 0 5px #e6f3ff"
            }
          },
          _focus: {
            boxShadow: "unset"
          },
          _hover: {
            boxShadow: "0 0 0 5px #F3F3F3",
            bg: "gray.50"
          }
        }
      }
    },
    BoxCard: {
      baseStyle: {
        display: "block",
        padding: {
          base: "1.5rem",
          md: "4 8",
          lg: "2rem 3rem"
        },
        borderRadius: "8",
        backgroundColor: "white"
      }
    },

    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: "primary.600",
            border: "none"
          },
          _focus: {
            boxShadow: "unset"
          }
        }
      }
    },

    Table: {
      baseStyle: {
        tr: {
          th: {
            textTransform: "unset",
            fontFamily: "CircularStdMedium",
            fontSize: "16px",
            color: "warmGray.600"
          },

          td: {
            color: "warmGray.600"
          }
        }
      }
    },

    Toast: {
      baseStyle: {
        bg: "red.300",
        p: "2",
        rounded: "sm",
        shadow: 6,
        // The stack in which toast are being rendered
        _stack: {
          margin: "auto",
          position: "absolute",
          space: 2,
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "box-none",
          _web: { position: "fixed" }
        },
        _overlay: {},
        _title: {
          color: "text.50",
          fontWeight: 700
        },
        _description: {
          color: "text.50",
          fontWeight: 400
        }
      }
    },

    Alert: {
      baseStyle: {
        container: {
          px: "0.8rem",
          py: "0.8rem"
        },
        title: {
          fontWeight: "bold",
          marginEnd: 2,
          lineHeight: "1rem"
        },
        description: {
          py: "1rem"
        },
        icon: {
          alignSelf: "start",
          flexShrink: 0,
          marginEnd: "0.5rem",
          w: "1rem",
          h: "1rem"
        }
      },

      variants: {
        errorToast: {
          container: {
            bg: "red.300"
          },
          title: {
            color: "white"
          },
          description: {
            color: "white"
          },
          icon: {
            color: "white"
          }
        }
      }
    }
  },
  View: {
    baseStyle: {
      width: "100%"
    }
  },
  styles: {
    global: {
      body: {
        backgroundColor: "tertiary.50",
        bg: "tertiary.50",
        background: "tertiary.50"
      },
      input: {
        "border-radius": "8px",
        rounded: "8px"
      },
      select: {
        "border-radius": "8px",
        rounded: "8px"
      }
    }
  },
  fontSizes: {
    "2xs": 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    "2xl": 24,
    "3xl": 30,
    "4xl": 36,
    "5xl": "48px",
    "6xl": 60,
    "7xl": 72,
    "8xl": 96,
    "9xl": 128
  }
}
