export interface XeroConnectionStatusDto {
  id: number
  name: XeroConnectionStatus
}

export enum XeroConnectionStatus {
  AwaitingAccountSelection = "AwaitingAccountSelection",
  AwaitingAuthorisation = "AwaitingAuthorisation",
  ChartOfAccountsPullInitiated = "ChartOfAccountsPullInitiated",
  Connected = "Connected",
  Disconnected = "Disconnected",
  InitialConnectionError = "InitialConnectionError",
  NotConnected = "NotConnected",
  PermissionGranted = "PermissionGranted",
  ConnectionError = "ConnectionError",
  Deauthorized = "Deauthorized",
  Unlinked = "Unlinked"
}

export enum PlatformType {
  Xero = "gbol"
}

export const getPlatformName = (value?: string) => {
  const indexOfS = Object.values(PlatformType).indexOf(value as unknown as PlatformType)
  const key = Object.keys(PlatformType)[indexOfS]
  return key
}
